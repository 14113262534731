var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"test-log"}},[_c('v-toolbar',{staticClass:"title",attrs:{"flat":"","height":"80"}},[_c('div',{staticClass:"text-no-wrap"},[_vm._v("Test cases")]),_c('v-chip',{staticClass:"mx-3",attrs:{"small":"","color":_vm.selectedItems.length > 0 ? 'success' : ''}}),_c('v-divider',{attrs:{"vertical":""}}),_c('v-subheader',{staticStyle:{"line-height":"20px"}},[(_vm.assignment.readOnly)?_c('span',[_vm._v("Selected test cases")]):_c('span',[_vm._v("Select your test cases")])]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-x":"","left":"","nudge-left":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){_vm.hideParameters = !_vm.hideParameters}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.hideParameters ? "mdi-eye" : "mdi-eye-off")+" ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.hideParameters ? "Show" : "Hide")+" parameter columns ")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"id":"test-log-table","value":_vm.selectedItems,"show-select":_vm.items && _vm.items.length > 0,"headers":_vm.filteredHeaders,"items":_vm.entries,"items-per-page":-1,"item-key":"runId","disable-sort":"","hide-default-footer":""},on:{"input":function (v) { return _vm.$emit('updateSelected', v); }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"my-2 white--text",attrs:{"type":"warning"}},[_vm._v(" Looks like your test cases log is empty. Run some tests to fill it up! ")])]},proxy:true},(_vm.blockUserInteraction)?{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
return [_c('v-simple-checkbox',{attrs:{"value":props.value,"indeterminate":props.indeterminate,"disabled":""}})]}}:null,{key:"item.runId",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.runId)?_c('div',_vm._g({staticClass:"text-no-wrap"},on),[_vm._v(" "+_vm._s(item.runId.slice(-3))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.runId))])])]}},(_vm.blockUserInteraction)?{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"disabled":""}})]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"disabled":_vm.blockUserInteraction,"outlined":"","icon":""},on:{"click":function($event){return _vm.deleteRunLog(item)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","icon":""},on:{"click":function($event){return _vm.viewTestDetails(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Report")])])],1)]}},{key:"item.testingTechnique",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"dense":"","hide-details":"","outlined":"","items":_vm.testingTechniques,"value":item.testingTechnique,"disabled":_vm.blockUserInteraction},on:{"change":function (v) { return _vm.$emit('modifyLogItem', item.id, 'testingTechnique', v); }}})]}},{key:"item.expectedOutput",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"readonly":"","dense":"","hide-details":"","outlined":"","disabled":_vm.blockUserInteraction,"value":item.expectedOutput},on:{"click":function($event){return _vm.openExpectedOutput(item.id, item.expectedOutput)},"click:clear":function (v) { return _vm.$emit('modifyLogItem', item.id, 'expectedOutput', ''); }}})]}},{key:"item.output",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap text-no-wrap align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","outlined":"","loading":_vm.loading[item.consoleId]},on:{"click":function($event){return _vm.openConsole(item.consoleId, item.error)}}},on),[(!item.error)?_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-console")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Console")])]),_c('div',{staticStyle:{"cursor":"pointer","font-family":"monospace","font-size":"12px"},on:{"click":function($event){return _vm.openConsole(item.consoleId, item.error)}}},[(item.output)?_c('span',[_vm._v(" "+_vm._s(item.output.length > 50 ? item.output.slice(0, 50) + "..." : item.output)+" ")]):(_vm.cachedOutput[item.consoleId])?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.cachedOutput[item.consoleId].length > 50 ? _vm.cachedOutput[item.consoleId].slice(0, 50) + "..." : _vm.cachedOutput[item.consoleId])))+" ")]):_vm._e()])],1)]}},{key:"item.testCasePassed",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.testCaseResults,"value":item.testCasePassed,"disabled":_vm.blockUserInteraction,"hide-details":"","dense":"","outlined":""},on:{"change":function (value) { return _vm.$emit('modifyLogItem', item.id, 'testCasePassed', value); }}})]}},_vm._l((_vm.parameterHeaders),function(parameterHeader){return {key:("item." + (parameterHeader.text)),fn:function(ref){
var item = ref.item;
return [_c('div',{key:parameterHeader.text},[(
            item[parameterHeader.text] &&
            item[parameterHeader.text].length > 20
          )?_c('div',{staticClass:"font-weight-medium",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openFullParameterText(
              parameterHeader.text,
              item[parameterHeader.text]
            )}}},[_vm._v(" "+_vm._s(item[parameterHeader.text].slice(0, 20))+"... ")]):_c('div',[_vm._v(_vm._s(item[parameterHeader.text]))])])]}}})],null,true)}),_c('custom-dialog',{attrs:{"readOnly":_vm.blockUserInteraction,"rows":2,"title":"Expected Output","initialText":_vm.currentItemExpectedOutput,"canSave":true},on:{"save":function (value) { return _vm.$emit('modifyLogItem', _vm.currentItemId, 'expectedOutput', value); },"close":function($event){_vm.showExpectedOutputDialog = false}},model:{value:(_vm.showExpectedOutputDialog),callback:function ($$v) {_vm.showExpectedOutputDialog=$$v},expression:"showExpectedOutputDialog"}}),_c('custom-dialog',{attrs:{"readOnly":true,"rows":2,"title":_vm.parameterHeader,"initialText":_vm.fullParameterText},on:{"close":function($event){_vm.showFullParameterText = false}},model:{value:(_vm.showFullParameterText),callback:function ($$v) {_vm.showFullParameterText=$$v},expression:"showFullParameterText"}}),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.consoleLog),callback:function ($$v) {_vm.consoleLog=$$v},expression:"consoleLog"}},[_c('v-card',{attrs:{"loading":_vm.loading[_vm.consoleID] ? 'white' : false,"dark":"","color":"black"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.loading[_vm.consoleID] ? "Fetching output" : "Console output")+" "),_c('v-spacer'),(_vm.summedTimeout)?_c('v-progress-circular',{attrs:{"value":_vm.perc,"rotate":"90","size":"32"}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(parseInt(_vm.perc)))])]):_vm._e(),(_vm.showTryAgainButton)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.tryAgainClicked()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeConsole}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"px-0":""}},[_c('pre',[_vm._v(_vm._s(_vm.cachedOutput[_vm.consoleID] === null ||
            _vm.cachedOutput[_vm.consoleID] === undefined
              ? "Please wait..."
              : _vm.cachedOutput[_vm.consoleID]))])])],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","transition":"slide-y-transition","max-width":"1200px"},model:{value:(_vm.testDetails.dialog),callback:function ($$v) {_vm.$set(_vm.testDetails, "dialog", $$v)},expression:"testDetails.dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Test report "+_vm._s(_vm.testDetails.ref)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.testDetails.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-import")]),_vm._v(" Input ")],1),_c('v-card-text',[_c('v-container',[_c('v-row',[(_vm.noTestParameters)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" No test parameters in this test case ")])],1):_vm._l((_vm.testDetails.parameters),function(value,field){return _c('v-text-field',{key:field,staticClass:"ma-3",attrs:{"value":value,"label":_vm.getLabel(field),"hide-details":"","readonly":"","filled":""},on:{"click":function($event){return _vm.openFullParameterText(field, value)}}})})],2)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-export")]),_vm._v(" Output ")],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Cached output","loading":_vm.loading[_vm.testDetails.consoleId],"value":_vm.testDetails.output ||
                            _vm.cachedOutput[_vm.testDetails.consoleId],"readonly":"","filled":"","hint":"Click on the console button to refresh.","persistent-hint":_vm.cachedOutput[_vm.testDetails.consoleId] !== null &&
                            _vm.cachedOutput[_vm.testDetails.consoleId] !== undefined,"append-icon":"mdi-console"},on:{"click":function($event){return _vm.openConsole(_vm.testDetails.consoleId)},"click:append":function($event){return _vm.openConsole(
                              _vm.testDetails.consoleId,
                              _vm.testDetails.error
                            )}}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }