<template>
  <v-dialog :value="value" @input="$emit('input', false)" width="600px">
    <v-card>
      <v-form ref="form">
        <v-card-title>
          {{ isNew ? "New" : community.name }} community
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.name"
                  :rules="nameRules"
                  filled
                  :disabled="!isNew"
                >
                  <template v-slot:label>
                    Name
                    <span class="red--text">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="item.repo"
                  :items="['Free', 'Paid']"
                  :rules="repoRules"
                  filled
                  :disabled="!isNew"
                >
                  <template v-slot:label>
                    License
                    <span class="red--text">*</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.number="item.maxUsers"
                  type="number"
                  label="Max users"
                  filled
                  :rules="maxUsersRules"
                  hint="Optional (0 = unlimited)"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="save" :loading="loading">
            <v-icon left color="success">mdi-content-save</v-icon>
            save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    community: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.item = { ...this.community };
          this.$refs.form?.resetValidation();
        }
      }
    }
  },
  data: () => ({
    item: {},
    valid: false,
    nameRules: [
      v => !!v || "Name is required",
      v => (v && v.length <= 255) || "Name must be less than 255 characters"
    ],
    repoRules: [v => !!v || "Repo is required"],
    maxUsersRules: [
      v =>
        v === null ||
        v === undefined ||
        v === "" ||
        (v >= 0 && v <= 200) ||
        "Max users must be between 0 and 200"
    ]
  }),
  computed: {
    ...mapGetters({
      loading: "getLoading"
    }),
    isNew() {
      return !this.community._id;
    }
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        if (this.isNew) {
          this.$emit("createCommunity", this.item);
        } else {
          this.$emit("updateCommunity", this.item);
        }
      }
    }
  }
};
</script>