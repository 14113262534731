<template>
    <div>
        <div
            v-if="
                isSuperAdmin ||
                currentRole === 'OWNER' ||
                currentRole === 'ADMIN' ||
                currentRole === 'MODERATOR'
            "
        >
            <v-navigation-drawer
                :value="value"
                @input="(v) => $emit('input', v)"
                absolute
                temporary
                hide-overlay
                class="elevation-0 preference-drawer"
                width="230px"
            >
                <v-list class="py-0">
                    <v-list-item
                        v-for="({ resource, icon }, index) in tabs"
                        :key="index"
                        @click="tab = index"
                        :class="{
                            primary: tab === index,
                            'white--text': !dark && tab === index,
                        }"
                    >
                        <v-list-item-icon>
                            <v-icon
                                :color="!dark && tab === index ? 'white' : ''"
                            >
                                {{ icon }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ resource }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <div
                :style="{
                    'margin-left':
                        value && $vuetify.breakpoint.mdAndUp
                            ? '230px'
                            : undefined,
                }"
            >
                <component
                    v-if="currentTabComponent"
                    :is="currentTabComponent"
                    :key="currentTab"
                    :search="search[currentTab]"
                    :currentView="currentView"
                    @activateClock="activateClock"
                    @tabChange="
                        (v) => (tab = tabs.findIndex((t) => t.resource === v))
                    "
                />
            </div>
        </div>
        <enabled v-model="clockMenu" :item="clock" />
    </div>
</template>

<script>
import Dashboard from './Dashboard.vue';
import Users from './Users.vue';
import Communities from './Communities.vue';
import Links from './Links.vue';
import DistributeAssignments from './DistributeAssignments';
import Assignments from './Assignments.vue';
import AssignmentCustomGroups from './AssignmentCustomGroups';
import Enabled from './Enabled.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        value: {
            type: Boolean,
        },
        search: {
            type: Object,
        },
        currentView: {
            type: String,
        },
    },
    components: {
        Dashboard,
        Users,
        Communities,
        Links,
        DistributeAssignments,
        AssignmentCustomGroups,
        Assignments,
        Enabled,
    },
    data: () => ({
        tab: 0,
        clock: null,
        clockMenu: false,
    }),
    created() {
        this.getAll();
    },
    computed: {
        ...mapGetters({
            currentRole: 'getCurrentRole',
            loggedUser: 'getLoggedUser',
        }),
        isSuperAdmin() {
            return this.loggedUser.isSuperAdmin;
        },
        tabs() {
            return [
                {
                    resource: 'Dashboard',
                    icon: 'mdi-view-dashboard',
                    component: Dashboard,
                },
                {
                    resource: 'Users',
                    icon: 'mdi-account',
                    component: Users,
                },
                {
                    resource: 'Communities',
                    icon: 'mdi-account-group',
                    component: Communities,
                    hide: this.currentRole === 'MODERATOR',
                },
                {
                    resource: 'Invitation Links',
                    icon: 'mdi-link',
                    component: Links,
                },
                {
                    resource: 'Assignments',
                    icon: 'mdi-book-open-page-variant',
                    component: Assignments,
                    hide: this.currentRole === 'MODERATOR',
                },
                {
                    resource: 'Custom Folders',
                    icon: 'mdi-folder-multiple-outline',
                    component: AssignmentCustomGroups,
                },
            ].filter((tab) => !tab.hide || this.isSuperAdmin);
        },
        dark() {
            return this.$vuetify.theme.dark;
        },
        currentTab() {
            if (this.tab === null || this.tab === undefined) return;
            return this.tabs[this.tab]?.resource;
        },
        currentTabComponent() {
            if (this.tab === null || this.tab === undefined) return;
            return this.tabs[this.tab]?.component;
        },
    },
    watch: {
        currentRole() {
            this.getAll();
        },
        currentTab() {
            this.$emit('tabChange', this.currentTab);
        },
    },
    methods: {
        ...mapActions({
            getCommunities: 'getCommunities',
            getAssignments: 'getAssignments',
            getRoles: 'getRoles',
            getUsers: 'getUsers',
            getLinks: 'getLinks',
        }),
        getAll() {
            if (!this.isSuperAdmin && this.currentRole === 'USER') return;
            Promise.all([
                this.getCommunities(),
                this.getRoles(),
                this.getUsers(),
                this.getLinks(),
                this.getAssignments(),
            ]);
        },
        activateClock(clock) {
            this.clockMenu = true;
            this.clock = clock;
        },
    },
};
</script>
<style scoped>
.preference-drawer {
    margin-top: 78px;
}

@media screen and (max-width: 1264px) {
    .preference-drawer {
        height: calc(100vh - 78px) !important;
    }
}

@media screen and (min-width: 1264px) {
    .preference-drawer {
        height: calc(90vh - 78px) !important;
    }
}
</style>
