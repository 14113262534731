<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-menu
      v-if="pointer === 'root'"
      bottom
      left
      offset-x
      nudge-left="10"
      :close-on-content-click="true"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon absolute top right>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-group :value="viewAll ? 1 : 0">
          <v-list-item @click="viewAll = false">
            <v-list-item-title>
              <v-icon left>mdi-view-grid</v-icon>
              Group by testing technique
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="viewAll = true">
            <v-list-item-title>
              <v-icon left>mdi-view-list</v-icon>
              List all available assignments
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-container v-if="folderContent && folderContent.length > 0" fluid>
      <v-row>
        <v-col
          xl="3"
          lg="4"
          sm="6"
          cols="12"
          class="pa-1"
          v-for="(node, i) in folderContent"
          :key="i"
        >
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  v-on="on"
                  @click="$emit('goto', node)"
                  class="explorer-card"
                  :elevation="hover ? 4 : 0"
                  outlined
                >
                  <v-img
                    v-if="node.image"
                    :src="node.image"
                    height="150"
                    class="grey elevation-0"
                  />
                  <v-card-title class="body-1">
                    <div class="headerClass">
                      <v-icon left size="35">
                        {{ isFolder(node) ? "mdi-folder" : "mdi-file" }}
                      </v-icon>
                      {{ node.name }}
                    </div>
                    <v-spacer />
                    <v-chip v-if="node.timeRemaining" class="subtitle-2 mx-2">
                      <v-icon left>mdi-clock</v-icon>
                      {{ node.timeRemaining }}
                    </v-chip>
                    <v-chip class="px-5 font-weight-black">
                      {{ node.submissions }}
                      {{ node.assignments ? " / " + node.assignments : "" }}
                    </v-chip>
                  </v-card-title>
                </v-card>
              </v-hover>
            </template>
            <span>{{ node.name }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ["pointer", "loading"],
  data: () => ({
    viewAll: false,
    intervalId: null
  }),
  computed: {
    items() {
      return this.$store.getters.getAssignmentTree;
    },
    folderContent() {
      const node = this.getNodeById(this.pointer, this.items);
      if (this.viewAll) return this.extractAllChildren(node);
      return node?.children || [];
    }
  },
  methods: {
    getNodeById(id, node) {
      var reduce = [].reduce;
      function runner(result, node) {
        if (result || !node) return result;
        return (
          (node.id === id && node) ||
          runner(null, node.children) ||
          reduce.call(Object(node), runner, result)
        );
      }
      return runner(null, node);
    },
    isFolder(node) {
      return Object.prototype.hasOwnProperty.call(node, "children");
    },
    extractAllChildren(node) {
      if (!node) return [];
      if (!node.children) return [node];
      return node.children.reduce(
        (acc, child) => [...acc, ...this.extractAllChildren(child)],
        []
      );
    },
    updateTimeRemaining() {
      this.folderContent.forEach(node => {
        if (node.deadline) {
          this.$set(
            node,
            "timeRemaining",
            this.getTimeRemaining(node.deadline)
          );
        }
      });
    },
    getTimeRemaining(deadline) {
      const currentTime = new Date();
      const deadlineTime = new Date(deadline);
      const timeDifference = deadlineTime - currentTime;
      if (timeDifference < 0) return "EXPIRED";
      const second = 1000;
      const minute = second * 60;
      const hour = minute * 60;
      const day = hour * 24;
      const days = Math.floor(timeDifference / day);
      const hours = Math.floor((timeDifference % day) / hour);
      const minutes = Math.floor((timeDifference % hour) / minute);
      const seconds = Math.floor((timeDifference % minute) / second);
      let timeString = "";
      if (days > 0) timeString += `${days}d `;
      if (hours > 0) timeString += `${hours}h `;
      if (minutes > 0) timeString += `${minutes}m `;
      if (!days && !hours && !minutes) timeString += `${seconds}s`;
      return timeString;
    }
  },
  mounted() {
    this.intervalId = setInterval(() => {
      this.updateTimeRemaining();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
};
</script>
<style>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.explorer-card {
  user-select: none;
  border-radius: 15px !important;
}
/* Fix for when border radius is set */
.v-card--link:before {
  background: none;
}
</style>