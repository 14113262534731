<template>
  <v-container id="communities" fluid class="pa-0">
    <v-toolbar class="mb-2" flat>
      <v-icon size="30" class="mr-2" color="yellow">mdi-lightbulb</v-icon>
      <v-toolbar-title>
        {{ communitiesDescription }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="openCommunityMenu" color="purple" dark>
        <span v-if="$vuetify.breakpoint.mdAndUp">New item</span>
        <v-icon v-else>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row
      v-if="currentView === 'Grid'"
      style="max-height: calc(90vh - 150px); overflow-y: auto"
    >
      <v-col
        v-for="community in filteredCommunities"
        :key="community._id"
        :cols="12"
        :lg="6"
      >
        <v-card height="100%" @click="openCommunityMenu(community)">
          <v-card-title>
            {{ community.name }}
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              large
              icon
              @click.stop="deleteCommunity(community._id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-subtitle>License: {{ community.repo }}</v-card-subtitle>
          <v-card-subtitle>
            Users:
            {{ community.numberOfUsers }} /
            {{ community.maxUsers === 0 ? "∞" : community.maxUsers }}
          </v-card-subtitle>
          <v-card-text class="mb-12">
            <v-chip-group>
              <v-chip
                v-for="role in community.roleID"
                :key="role._id"
                class="mr-1"
                @click.stop="openUpdateRoleMenu(role)"
              >
                {{ role.name }}
              </v-chip>
              <v-btn icon @click.stop="openNewRoleMenu(community)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-chip-group>
          </v-card-text>
          <v-card-actions>
            <div style="position: absolute; bottom: 0">
              <enabled-chip
                :title="`Enabled for ${community.name}`"
                :patchObject="{
                  _id: community._id,
                  enabled: community.enabled
                }"
                :patchCall="patchCommunity"
              />
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="currentView === 'Table'">
      <v-col cols="12">
        <v-data-table
          :items="filteredCommunities"
          fixed-header
          height="calc(90vh - 230px)"
          :headers="headers"
          :items-per-page="25"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100, -1]
          }"
          :loading="loading"
          class="elevation-0 clickable-row"
          @click:row="openCommunityMenu"
        >
          <template v-slot:[`item.roles`]="{ item }">
            <v-chip-group>
              <v-chip
                v-for="role in item.roleID"
                :key="role._id"
                class="ma-1"
                @click.stop="openUpdateRoleMenu(role)"
              >
                {{ role.name }}
              </v-chip>
              <v-btn icon @click.stop="openNewRoleMenu(item)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-chip-group>
          </template>
          <template v-slot:[`item.users`]="{ item }">
            {{ item.numberOfUsers }} /
            {{ item.maxUsers === 0 ? "∞" : item.maxUsers }}
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <enabled-chip
              :title="`Enabled for ${item.name}`"
              :patchObject="{
                _id: item._id,
                enabled: item.enabled
              }"
              :patchCall="patchCommunity"
            />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="red" icon @click.stop="deleteCommunity(item._id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <community-dialog
      v-model="communityMenu"
      :community="community"
      @createCommunity="createCommunity"
      @updateCommunity="updateCommunity"
      @activateClock="$emit('activateClock', community)"
    ></community-dialog>
    <role-dialog
      v-model="roleMenu"
      :role="role"
      @permissionChange="permissionChange"
      @deleteResource="deleteResource"
      @activateClock="$emit('activateClock', role)"
    ></role-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import RoleDialog from "./RoleDialog.vue";
import CommunityDialog from "./CommunityDialog.vue";
import EnabledChip from "@/components/cms/EnabledChip.vue";

export default {
  components: {
    RoleDialog,
    CommunityDialog,
    EnabledChip
  },
  props: {
    search: String,
    currentView: String
  },
  data: () => ({
    community: {},
    communityMenu: false,
    roleMenu: false,
    role: {},
    communitiesDescription: "Communities with roles"
  }),
  created() {
    this.getCommunities();
  },
  computed: {
    ...mapGetters({
      loading: "getLoading",
      communities: "getCommunities",
      loggedUser: "getLoggedUser"
    }),
    filteredCommunities() {
      if (!this.search) return this.communities;
      return this.communities.filter(community => {
        const { name, repo } = community;
        const search = this.search.toLowerCase();
        return (
          name.toLowerCase().includes(search) ||
          repo.toLowerCase().includes(search)
        );
      });
    },
    headers() {
      return [
        {
          text: "Name",
          value: "name",
          width: "250px"
        },
        {
          text: "License",
          value: "repo",
          width: "100px"
        },
        {
          text: "Users",
          value: "users",
          width: "100px"
        },
        {
          text: "Roles",
          value: "roles",
          width: "250px",
          sortable: false
        },
        {
          text: "Enabled",
          value: "enabled",
          sortable: false
        }
        // {
        //   text: "Actions",
        //   value: "actions",
        //   width: "110px",
        //   sortable: false
        // }
      ];
    }
  },
  methods: {
    ...mapActions({
      getCommunities: "getCommunities",
      addCommunity: "addCommunity",
      patchCommunity: "patchCommunity",
      deleteCommunity: "deleteCommunity",
      deleteRole: "deleteRole"
    }),
    createCommunity(community) {
      this.addCommunity(community).then(() => {
        this.communityMenu = false;
      });
    },
    updateCommunity(community) {
      this.patchCommunity({
        id: community._id,
        data: { maxUsers: community.maxUsers } // sets maxUsers
      }).then(() => {
        this.communityMenu = false;
      });
    },
    openCommunityMenu(community) {
      this.communityMenu = true;
      this.community = community;
    },
    openUpdateRoleMenu(role) {
      this.roleMenu = true;
      this.role = role;
    },
    openNewRoleMenu(community) {
      this.roleMenu = true;
      this.role = {
        _id: null,
        name: "",
        communityID: community,
        can: {
          USER: [],
          ASSIGNMENT: [],
          COMMUNITY: [],
          ROLE: [],
          SUBMISSION: [],
          RUNLOG: [],
          DOCKERLOG: [],
          ASSIGNMENTACCESS: [],
          ASSIGNMENTOVERRIDE: [],
          ASSIGNMENTGROUP: [],
          FEEDBACK: []
        },
        enabled: {
          val: true,
          from: new Date(),
          to: new Date(8.64e15)
        }
      };
    },
    permissionChange(resource, permission, value) {
      if (value) {
        if (!this.role.can[resource]) this.$set(this.role.can, resource, []);
        this.role.can[resource].push(permission);
      } else {
        this.role.can[resource] = this.role.can[resource].filter(
          p => p !== permission
        );
      }
    },
    deleteResource(resource) {
      Vue.delete(this.role.can, resource);
    }
  }
};
</script>
<style scoped>
.clickable-row >>> tbody tr :hover {
  cursor: pointer;
}
</style>
