<template>
  <div>
    <pop-up
      v-model="popup"
      icon="mdi-alert"
      iconColor="warning"
      :dividerColor="$vuetify.theme.dark ? `#e27e36` : `#FFC107`"
      titleText="Warning"
      :text="popupText"
      :confirmationText="popupConfirmationText"
      type="changeRoute"
      @confirm="popupConfirmed"
    />
    <v-navigation-drawer
      app
      ref="drawer"
      v-model="primaryDrawer.model"
      :mini-variant="primaryDrawer.mini"
      :width="primaryDrawer.width"
      temporary
    >
      <v-text-field
        :value="search"
        @input="updateSearch"
        :loading="searchLoading"
        append-icon="mdi-magnify"
        spellcheck="false"
        label="Search"
        single-line
        solo
        hide-details
        clearable
        class="ma-2"
      ></v-text-field>
      <v-treeview
        :open="open"
        :active="active"
        :items="items"
        :search="searchTree"
        ref="treeview"
        dense
        transition
        open-on-click
        return-object
        @update:open="arr => (open = arr)"
        style="padding-bottom: 120px"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="Object.prototype.hasOwnProperty.call(item, 'children')">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <v-icon v-else>mdi-file</v-icon>
        </template>

        <template v-slot:label="{ item }">
          <v-btn
            @click="nodeRequested(item)"
            color="transparent"
            class="text-none"
            style="box-shadow: none"
          >
            {{ item.name }}
          </v-btn>
        </template>
      </v-treeview>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-progress-linear
        class="dbugit-loading"
        height="5px"
        indeterminate
        :active="loading"
      ></v-progress-linear>
      <v-app-bar-nav-icon
        @click.stop="primaryDrawer.model = !primaryDrawer.model"
      />
      <v-img
        class="mt-2"
        height="65"
        max-width="75"
        contain
        :src="
          require($vuetify.theme.dark
            ? '../../assets/logo_dbugit_dark.svg'
            : '../../assets/logo_dbugit_light.svg')
        "
      />
      <v-spacer />
      <settings-menu
        @preferences="preferences = true"
        @profile="profile = true"
        @logout="logoutRequested"
      />
    </v-app-bar>
    <breadcrumbs @goto="generateDrawer" />
    <explorer
      v-if="!file.id"
      :pointer="currentNode"
      :loading="explorerLoading"
      @goto="selectNode"
    />
    <v-dialog
      v-model="preferences"
      transition="slide-y-transition"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      scrollable
      @keydown.escape="preferences = false"
      overlay-opacity="0.95"
      class="rounded-xl"
    >
      <preferences @close="preferences = false" />
    </v-dialog>
    <v-dialog
      v-model="profile"
      width="700px"
      scrollable
      @keydown.esc="profile = false"
    >
      <profile @close="profile = false" />
    </v-dialog>
  </div>
</template>

<script>
import Breadcrumbs from "../navigation/Breadcrumbs";
import Explorer from "../navigation/Explorer";
import SettingsMenu from "./SettingsMenu";
import PopUp from "../core/PopUp";
import Profile from "../core/Profile";
// Services
import Login from "@/services/LoginService";
import Logout from "@/services/LogoutService";
import Assignment from "@/services/AssignmentService";
// Preferences
import Preferences from "../../views/Preferences";

export default {
  components: {
    Breadcrumbs,
    Explorer,
    SettingsMenu,
    PopUp,
    Profile,
    Preferences
  },
  props: {
    permissionDenied: Boolean,
    loading: Boolean,
    readOnly: Boolean
  },
  data: () => ({
    popup: false,
    popupConfirmationText: "D",
    popupText: "",
    preferences: false,
    profile: false,
    currentNode: "root",
    file: {
      id: null,
      name: ""
    },
    active: [],
    requestedFile: {},
    fileSnapshot: {},
    generatedNodes: [],
    generatedNodesObjs: [],
    currentRoute: "",
    primaryDrawer: {
      model: false,
      mini: false,
      width: "380px",
      borderSize: 3
    },
    search: null,
    searchTree: null,
    searchLoading: false,
    explorerLoading: false,
    open: [],
    snapshot_open: []
  }),
  computed: {
    items() {
      return this.$store.getters.getAssignmentTree;
    },
    unsavedChangesToSubmission() {
      return this.$store.getters.getUnsavedChangesToSubmission;
    }
  },
  created() {
    this.isLoggedin();
    // Handle back and forward
    window.onpopstate = () => this.generateDrawer();
  },
  mounted() {
    this.enableDrawerResize();
  },
  methods: {
    pushRoute(route) {
      if (route && route !== this.$route.path) {
        this.$router.push(route);
      }
    },
    popupConfirmed(type) {
      switch (type) {
        case "drawer":
          this.openFile([this.fileSnapshot]);
          this.fileSnapshot = {};
          break;
        case "logout":
          this.logout();
          break;
      }
    },
    async nodeRequested(node) {
      // Try to call openFile only if the requested node is a file and is NOT the already active file. Otherwise just ignore this.
      if (
        !Object.prototype.hasOwnProperty.call(node, "children") &&
        JSON.stringify(node) !== JSON.stringify(this.active[0])
      ) {
        if (this.unsavedChangesToSubmission) {
          this.fileSnapshot = node;
          this.popupConfirmationText = "switch anyway";
          this.popupText =
            "Freeze! You have unsaved changes in your submission! Note that switching to another assignment without submitting first will result in losing your progress.";
          this.popup = true;
        } else {
          this.openFile([node]);
        }
      } else if (!Array.isArray(node.children) || !node.children.length) {
        await this.getUserAssignments(node);
        this.open.push(node);
      }
    },
    async getUserAssignments(node) {
      this.explorerLoading = true;
      try {
        const { data } = await Assignment.getUserAssignments(node.id);
        this.$store.commit("setUserAssignmentTree", {
          id: node.id,
          children: data.children
        });
      } finally {
        this.explorerLoading = false;
      }
    },
    selectNode(node) {
      const isFile = !Object.prototype.hasOwnProperty.call(node, "children");
      if (isFile) {
        this.openFile([node]);
      } else {
        const { path } = this.$router.currentRoute;
        const route = `${path}/${node.name.replace(/\s+/g, "--")}`;
        this.pushRoute(route);
      }
      this.generateDrawer();
    },
    updateSearch(input) {
      // Update Text field props
      this.search = input;
      this.searchLoading = true;

      // Update searchTree if half second passes since last input
      this.searchTimeout && clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.searchTree = this.search;
        this.searchLoading = false;
      }, 500);
    },
    async generateDrawer() {
      this.prepareEntry();
      await this.checkEntry();
      this.commitEntry();
    },
    prepareEntry() {
      this.generatedNodes = [];
      this.generatedNodesObjs = [];
      for (let item of this.$router.currentRoute.path.split("/")) {
        if (item.length) {
          this.generatedNodes.push(item.replace(/--/g, " "));
        }
      }
    },
    async checkEntry() {
      // A function that fills in the {this.generatedNodesObjs} array
      // with objects, folders from the assignment tree {this.items}
      // that have the same name in the {this.generatedNodes} array
      let dir = this.items;
      for (const name of this.generatedNodes) {
        const obj = dir.find(d => name === d.name);
        if (!obj) {
          continue;
        } else if (!obj.children) {
          this.requestedFile = obj;
          continue;
        }
        // If the folder is empty and not Students
        if (!obj.children.length && obj.name !== "Students") {
          await this.getUserAssignments(obj);
        }
        this.generatedNodesObjs.push(obj);
        dir = obj.children;
      }
    },
    commitEntry() {
      // Sync the tree with the generated objects from the route
      this.open = [...this.generatedNodesObjs];
      // Get id of current node (last)
      const last = this.generatedNodesObjs.slice(-1)[0];
      this.currentNode = last?.id;
      // if an assignment is requested, open it
      if (Object.keys(this.requestedFile).length) {
        this.openFile([this.requestedFile]);
        this.requestedFile = {};
      }
      // else close any opened files
      else {
        this.active = [];
        this.closeFile();
      }
    },
    enableDrawerResize() {
      // const minSize = this.primaryDrawer.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      // RTL Support
      // const direction = el.classList.contains("v-navigation-drawer--right")
      //   ? "right"
      //   : "left"

      drawerBorder.style.width = this.primaryDrawer.borderSize + "px";
      drawerBorder.style.cursor = "ew-resize";
      drawerBorder.style.backgroundColor = "grey";

      function resize(e) {
        // direction === "right"
        //   ? document.body.scrollWidth - e.clientX
        //   : e.clientX
        document.body.style.cursor = "ew-resize";
        if (e.clientX > 370 && e.clientX < 700)
          el.style.width = e.clientX + "px";
      }
      function fixBorderSize() {
        drawerBorder.style.width = 3 + "px";
        drawerBorder.style.backgroundColor = "grey";
      }

      drawerBorder.addEventListener(
        "mousedown",
        () => {
          el.style.transition = "initial";
          document.addEventListener("mousemove", resize, false);
        },
        false
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.primaryDrawer.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
      drawerBorder.addEventListener(
        "mouseover",
        () => {
          drawerBorder.style.width = 20 + "px";
          drawerBorder.style.background =
            "linear-gradient(to right, rgba(200, 200, 200, 0) 0%, rgba(200, 200, 200, 1) 100%)";
          drawerBorder.addEventListener("mouseout", fixBorderSize, false);
        },
        false
      );
    },
    constructRoute(array, fileID) {
      for (let obj of array) {
        if (obj.children) {
          this.currentRoute =
            this.currentRoute + "/" + obj.name.replace(/\s+/g, "--");
          if (this.constructRoute(obj.children, fileID)) {
            return true;
          }
        } else if (obj.id === fileID) {
          this.currentRoute =
            this.currentRoute + "/" + obj.name.replace(/\s+/g, "--");
          return true;
        }
      }
      this.currentRoute = this.currentRoute.replace(
        "/" + this.currentRoute.split("/").pop(),
        ""
      );
      return false;
    },
    openFile(file) {
      if (file && file[0]) {
        // TODO : Check if the file has id and name
        const { id, name } = file[0];
        // Construct new route
        if (this.constructRoute(this.items, id)) {
          // Push route
          this.pushRoute(this.currentRoute);
          // Clean constructed route
          this.currentRoute = "";
          // Save the file
          this.file.id = id;
          this.file.name = name;
          // Update active node
          this.active = file;
          // Notify parent
          this.$emit("filechange", this.file.id.toString());
        }
      }
    },
    async isLoggedin() {
      this.explorerLoading = true;
      try {
        const res = await Login.isLoggedin();
        this.$store.commit("setIsUserLoggedin", res.data.success);
        this.$store.commit("setLoggedUser", res.data.user);
        this.$store.commit("setAssignmentTree", res.data.tree);
        this.generateDrawer();
      } catch {
        this.$store.commit("setIsUserLoggedin", false);
        this.pushRoute("/welcome");
      } finally {
        this.explorerLoading = false;
      }
    },
    routerShift() {
      const { path } = this.$router.currentRoute;
      const toReplace = "/" + path.split("/").pop();
      const newRoute = path.replace(toReplace, "");
      this.pushRoute(newRoute);
      this.generateDrawer();
    },
    closeFile() {
      this.$emit("filechange", null); // Close the file
      this.file.id = null;
      this.file.name = "";
    },
    logoutRequested() {
      if (this.unsavedChangesToSubmission) {
        this.popupConfirmationText = "log me out";
        this.popupText =
          "Freeze! You have unsaved changes to your submission! Note that logging out without submitting first will result in losing your progress.";
        this.popup = true;
      } else {
        this.logout();
      }
    },
    async logout() {
      this.explorerLoading = true;
      try {
        const res = await Logout.logout();
        this.$store.commit("setAssignmentTree", res.data.tree);
        this.$store.commit("setIsUserLoggedin", false);
        this.explorerLoading = false;
        //this.pushRoute("/welcome");
        window.reload(true);
      } catch {
        this.isLoggedin();
      }
      this.closeFile();
    }
  },
  watch: {
    searchTree(newVal, oldVal) {
      if (!oldVal) {
        this.snapshot_open = this.open;
        this.$refs.treeview.updateAll(true);
      } else if (!newVal) {
        this.open = [...this.snapshot_open];
      }
    },
    async permissionDenied(val) {
      if (val) {
        await this.isLoggedin();
        if (this.$store.getters.getIsUserLoggedin) {
          this.routerShift();
          this.active = [];
          this.closeFile();
        } else {
          this.logout();
        }
        this.$emit("restorePermission");
      }
    }
  }
};
</script>
<style>
.dbugit-loading {
  position: absolute;
  top: 0;
  margin-left: -16px;
}
</style>
