import { render, staticRenderFns } from "./PreferencesNavigation.vue?vue&type=template&id=7dd81ae4&scoped=true&"
import script from "./PreferencesNavigation.vue?vue&type=script&lang=js&"
export * from "./PreferencesNavigation.vue?vue&type=script&lang=js&"
import style0 from "./PreferencesNavigation.vue?vue&type=style&index=0&id=7dd81ae4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd81ae4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VNavigationDrawer})
