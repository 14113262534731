<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col xs="12" lg="8" xl="4">
        <v-card id="registerCard" @keydown.enter="addUserWithLink">
          <v-img
            v-if="$vuetify.breakpoint.mdAndUp"
            :src="`/api/image/register/800/250`"
            :lazy-src="require('../assets/update_profile_pic.jpg')"
            height="15vh"
          ></v-img>
          <v-card-title>Signup</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12" md="4" xl="12" class="py-1">
                    <v-text-field
                      v-model="user.username"
                      label="Username"
                      autocomplete="username"
                      dense
                      :rules="[
                        rules.required,
                        rules.minLength(4),
                        rules.maxLength(48),
                        rules.noWhitespace
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" xl="6" class="py-1">
                    <v-text-field
                      v-model="user.password"
                      autocomplete="new-password"
                      label="Password"
                      dense
                      :append-icon="
                        hiddenModePassword ? 'visibility' : 'visibility_off'
                      "
                      @click:append="hiddenModePassword = !hiddenModePassword"
                      :type="hiddenModePassword ? 'password' : 'text'"
                      :rules="[
                        rules.required,
                        rules.minLength(8),
                        rules.maxLength(72)
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" xl="6" class="py-1">
                    <v-text-field
                      v-model="user.confirmPassword"
                      autocomplete="confirm-password"
                      label="Confirm password"
                      dense
                      :append-icon="
                        hiddenModeConfirmPassword
                          ? 'visibility'
                          : 'visibility_off'
                      "
                      @click:append="
                        hiddenModeConfirmPassword = !hiddenModeConfirmPassword
                      "
                      :type="hiddenModeConfirmPassword ? 'password' : 'text'"
                      :rules="[
                        rules.required,
                        rules.minLength(8),
                        rules.maxLength(72)
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" xl="12" class="py-1">
                    <v-text-field
                      v-model="user.email"
                      label="Email"
                      autocomplete="email"
                      dense
                      :rules="[rules.required, rules.validEmail]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="4" xl="6" class="py-1">
                    <v-text-field
                      v-model="user.firstName"
                      label="First name"
                      autocomplete="given-name"
                      dense
                      :rules="[
                        rules.required,
                        rules.maxLength(48),
                        rules.alphaSpace
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="4" xl="6" class="py-1">
                    <v-text-field
                      v-model="user.lastName"
                      label="Last name"
                      autocomplete="family-name"
                      dense
                      :rules="[
                        rules.required,
                        rules.maxLength(48),
                        rules.alphaSpace
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="!$route.query.refLink" cols="12" class="py-1">
                    <v-text-field
                      v-model="user.link"
                      label="Invitation code or link"
                      dense
                      :rules="[rules.validUUID]"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0">
                    <v-switch color="pink" v-model="user.useEmailForResearch">
                      <template v-slot:label>
                        <div>
                          I agree to receive a survey by email to help improve
                          DBugIT.
                        </div>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-btn block x-large dark @click="addUserWithLink">Signup</v-btn>
          </v-card-text>
        </v-card>
        <v-alert
          :value="alert"
          @input="alert = false"
          transition="slide-y-transition"
          type="error"
          dismissible
        >
          {{ message }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PreferencesService from "@/services/PreferencesService";
import { rules } from "@/utils/rules";

export default {
  data: () => ({
    user: {
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      firstName: "",
      lastName: "",
      link: null,
      useEmailForResearch: false
    },
    hiddenModePassword: true,
    hiddenModeConfirmPassword: true,
    alert: false,
    message: "",
    rules
  }),
  computed: {
    link() {
      return this.user.link;
    }
  },
  methods: {
    async addUserWithLink() {
      if (!this.$refs.form.validate()) return;
      if (this.user.password !== this.user.confirmPassword) {
        this.alert = true;
        this.message = "Passwords didn't match";
        this.user.confirmPassword = "";
      } else {
        const request = !this.user.link ? "postUser" : "postUserWithLink";
        try {
          await PreferencesService[request](this.user);
          this.alert = false;
          this.$router.push("/login");
        } catch (err) {
          this.alert = true;
          this.message = err.response.data.message;
        }
      }
    }
  },
  watch: {
    link(val) {
      const invLinkBase = `${window.location.origin}${this.$route.path}?refLink=`;
      const leftPart = val.substring(0, val.indexOf("=") + 1);
      const rightPart = val.substring(val.indexOf("=") + 1, val.length);
      if (
        leftPart === invLinkBase &&
        rightPart &&
        this.rules.validUUID(rightPart) !== "Invalid code format"
      ) {
        this.user.link = rightPart;
      }
    }
  },
  created() {
    if (this.$route.query.refLink) {
      this.user.link = this.$route.query.refLink;
    }
  }
};
</script>
