// https://vuex.vuejs.org/en/getters.html

export default {
    getCommunities: state => state.communities,
    getRoles: state => state.roles,
    getUsers: state => state.users,
    getLinks: state => state.links,
    getAssignments: state => state.assignments,
    getAssignmentCustomGroups: state => state.assignmentCustomGroups,
    getDashboard: state => state.dashboard,
    getStatistics: state => state.statistics,
    getLoading: state => state.loading > 0,
    getLoggedUser: state => state.loggedUser,
    getIsUserLoggedin: state => state.isUserLoggedin,
    getAssignmentTree: state => state.tree,
    getRootName: state => '/' + state.tree[0].name.replace(/\s+/g, "--"),
    getUnsavedChangesToSubmission: state => state.unsavedChangesToSubmission,
    getNotViewedLogs: state => fileId => {
        const index = fileId?.toString();
        return index ? state.notViewedLogs[index] || 0 : 0;
    },
    getCurrentRole: state => {
        const user = state.loggedUser;
        if (!state.isUserLoggedin || !user.roleIDActive) return null;
        const role = user.roleID.find(x => x._id == user.roleIDActive);
        if (!role) return null;
        return role.name;
    },
    getCurrentCommunity: state => {
        const user = state.loggedUser;
        if (!state.isUserLoggedin || !user.roleIDActive) return null;
        const role = user.roleID.find(x => x._id == user.roleIDActive);
        if (!role) return null;
        return role.communityID.name;
    },
}
