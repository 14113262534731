import axiosInstance from '@/services/AxiosInstance';

export default {
getAssignmentCustomGroups() {
    return axiosInstance({
        method: 'get',
        url: 'assignmentCustomGroup/',
    });
},
postAssignmentCustomGroup(data) {
    return axiosInstance({
        method: 'post',
        url: 'assignmentCustomGroup/',
        data
    });
},
patchAssignmentCustomGroup(id, data) {
    return axiosInstance({
        method: 'patch',
        url: 'assignmentCustomGroup/' + id,
        data
    });
},
deleteAssignmentCustomGroup(id) {
    return axiosInstance({
        method: 'delete',
        url: 'assignmentCustomGroup/' + id,
    });
},
}