var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{ref:"menuRef",attrs:{"transition":"slide-y-transition","bottom":"","offset-y":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(_vm.isLogged)?_c('v-btn',_vm._g({staticClass:"px-0",attrs:{"x-large":"","text":""}},Object.assign({}, tooltip, menu)),[_c('div',[_c('v-avatar',{staticClass:"primary darken-2",attrs:{"size":"42"}},[(_vm.loggedUser.username)?_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.loggedUser.username.slice(0, 2))+" ")]):_vm._e()]),_c('v-icon',{attrs:{"color":"primary","size":"21"}},[_vm._v("mdi-chevron-down")])],1)]):_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("settings")])],1)]}}],null,true)},[_c('span',[_vm._v("Settings")])])]}}])},[_c('v-card',[_c('v-container',{attrs:{"pa-0":""}},[_c('v-list',{attrs:{"shaped":"","dense":""}},[(_vm.isLogged)?_c('v-list-item',{staticClass:"px-2 py-2",on:{"click":function($event){return _vm.perform('profile')}}},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-avatar',{staticClass:"primary darken-2",attrs:{"size":"55"}},[_c('v-icon',{attrs:{"color":"white","size":"24"}},[_vm._v(" mdi-account ")])],1)],1),_c('v-col',{staticClass:"text-center"},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.loggedUser.firstName)+" "+_vm._s(_vm.loggedUser.lastName)+" ")]),_c('div',{staticClass:"subheader"},[_vm._v(" "+_vm._s(_vm.loggedUser.username)+" ")]),_c('div',{staticClass:"subheader"},[_vm._v(" "+_vm._s(_vm.loggedUser.email)+" ")])])],1)],1):_vm._e(),_c('v-list-item',{staticClass:"mb-5"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Roles")]),_c('profile-switcher')],1)],1),(_vm.isLogged)?_c('v-divider',{staticClass:"my-1"}):_vm._e(),(
                        (_vm.isLogged && _vm.loggedUser.isSuperAdmin) ||
                        _vm.currentRole !== 'USER'
                    )?_c('v-list-item',{on:{"click":function($event){return _vm.perform('preferences')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-title',[_vm._v("Preferences")])],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.dark = !_vm.dark}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.dark ? 'white' : 'black'}},[_vm._v(" mdi-invert-colors ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Turn "+_vm._s(_vm.dark ? 'on' : 'off')+" the lights ")])],1)],1),(_vm.isLogged)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.perform('logout')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Log Out")])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }