import isEmail from "validator/lib/isEmail";
import { validate } from "uuid";

export const rules = {
    required: v => !!v || "This field is required.",
    minLength: length => v => v.length >= length || `Minimum ${length} characters`,
    maxLength: length => v => v.length <= length || `Maximum ${length} characters`,
    validEmail: v => isEmail(v) || "Please enter a valid email address",
    noWhitespace: v => !v.includes(" ") || "Whitespaces are not allowed",
    validUUID: v => !v || validate(v) || "Invalid code format", // Optional field
    alphaSpace: v =>
        /^([a-zA-Z ]+)$/.test(v) || "Only letters and spaces allowed"
}