var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
            _vm.isSuperAdmin ||
            _vm.currentRole === 'OWNER' ||
            _vm.currentRole === 'ADMIN' ||
            _vm.currentRole === 'MODERATOR'
        )?_c('div',[_c('v-navigation-drawer',{staticClass:"elevation-0 preference-drawer",attrs:{"value":_vm.value,"absolute":"","temporary":"","hide-overlay":"","width":"230px"},on:{"input":function (v) { return _vm.$emit('input', v); }}},[_c('v-list',{staticClass:"py-0"},_vm._l((_vm.tabs),function(ref,index){
        var resource = ref.resource;
        var icon = ref.icon;
return _c('v-list-item',{key:index,class:{
                        primary: _vm.tab === index,
                        'white--text': !_vm.dark && _vm.tab === index,
                    },on:{"click":function($event){_vm.tab = index}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":!_vm.dark && _vm.tab === index ? 'white' : ''}},[_vm._v(" "+_vm._s(icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(resource)+" ")])],1)],1)}),1)],1),_c('div',{style:({
                'margin-left':
                    _vm.value && _vm.$vuetify.breakpoint.mdAndUp
                        ? '230px'
                        : undefined,
            })},[(_vm.currentTabComponent)?_c(_vm.currentTabComponent,{key:_vm.currentTab,tag:"component",attrs:{"search":_vm.search[_vm.currentTab],"currentView":_vm.currentView},on:{"activateClock":_vm.activateClock,"tabChange":function (v) { return (_vm.tab = _vm.tabs.findIndex(function (t) { return t.resource === v; })); }}}):_vm._e()],1)],1):_vm._e(),_c('enabled',{attrs:{"item":_vm.clock},model:{value:(_vm.clockMenu),callback:function ($$v) {_vm.clockMenu=$$v},expression:"clockMenu"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }