<template>
  <v-dialog
    :value="value"
    max-width="700px"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card dark color="black">
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="text"
          ref="textarea"
          :readonly="readOnly"
          :rows="rows"
          auto-grow
          hide-details
          solo
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="canSave"
          @click="
            $emit('save', text);
            $emit('close');
          "
        >
          <v-icon left color="success">mdi-content-save</v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    readOnly: {
      type: Boolean,
      default: false
    },
    rows: Number,
    title: String,
    initialText: String,
    canSave: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: ""
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.text = this.initialText;
        setTimeout(() => {
          this.$refs.textarea.focus();
        }, 100);
      }
    }
  }
};
</script>
