<template>
  <v-container id="users" fluid class="pa-0">
    <v-toolbar class="mb-2" flat>
      <v-icon size="30" class="mr-2" color="yellow">mdi-lightbulb</v-icon>
      <v-toolbar-title>
        Users in the active
        <strong>
          {{ currentCommunity }}
        </strong>
        community
      </v-toolbar-title>
    </v-toolbar>
    <v-row
      v-if="currentView === 'Grid'"
      style="max-height: calc(90vh - 150px); overflow-y: auto"
    >
      <v-col
        v-for="user in filteredUsers"
        :key="user._id"
        :cols="12"
        :lg="6"
        :xl="4"
      >
        <v-card height="100%" @click="openUpdateUserMenu(user)">
          <v-card-title>
            {{ user.firstName }} {{ user.lastName }}
            <v-chip v-if="user.admin" class="ml-2 red darken-3">
              <v-icon color="white">mdi-star</v-icon>
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn color="red" icon @click.stop="deleteUser(user._id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            <div>
              {{ user.username }}
            </div>
            <div>
              {{ user.email }}
            </div>
          </v-card-subtitle>
          <div class="mx-5">
            <v-chip outlined small>
              Runs:
              {{ user.runs }}
            </v-chip>
            <v-chip outlined small>
              Submissions:
              {{ user.submissions }}
            </v-chip>
          </div>
          <v-card-text class="mb-12">
            <v-chip-group column>
              <v-chip
                v-for="role in user.roleID"
                :key="role._id"
                :color="roleChipColor(user, role)"
                small
                outlined
              >
                {{ role.name }}
                {{
                  loggedUser.isSuperAdmin ? "in " + role.communityID.name : ""
                }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
          <v-card-actions v-if="user.enabled">
            <div style="position: absolute; bottom: 0">
              <enabled-chip
                :title="`Enabled for ${user.firstName} ${user.lastName} (${user.username})`"
                :patchObject="{
                  _id: user._id,
                  enabled: user.enabled
                }"
                :patchCall="patchUser"
              />
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="currentView === 'Table'">
      <v-col cols="12">
        <v-data-table
          :items="filteredUsers"
          fixed-header
          height="calc(90vh - 230px)"
          :headers="headers"
          :items-per-page="25"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100, -1]
          }"
          :loading="loading"
          class="elevation-0 clickable-row"
          multi-sort
          @click:row="openUpdateUserMenu"
        >
          <template v-slot:[`item.roles`]="{ item }">
            <v-chip-group column>
              <v-chip
                v-for="role in item.roleID"
                :key="role._id"
                :color="roleChipColor(item, role)"
                small
                outlined
              >
                {{ role.name }}
                {{
                  loggedUser.isSuperAdmin ? "in " + role.communityID.name : ""
                }}
              </v-chip>
            </v-chip-group>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.firstName }} {{ item.lastName }}
            <v-chip v-if="item.admin" class="ml-2 red darken-3">
              <v-icon color="white">mdi-star</v-icon>
            </v-chip>
          </template>
          <template v-slot:[`item.runs`]="{ item }">
            <v-chip outlined>
              {{ item.runs }}
            </v-chip>
          </template>
          <template v-slot:[`item.submissions`]="{ item }">
            <v-chip outlined>
              {{ item.submissions }}
            </v-chip>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <enabled-chip
              :title="`Enabled for ${item.firstName} ${item.lastName} (${item.username})`"
              :patchObject="{ _id: item._id, enabled: item.enabled }"
              :patchCall="patchUser"
            />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="red" icon @click.stop="deleteUser(item._id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="userMenu"
      width="800px"
      @keydown.esc="userMenu = false"
      scrollable
    >
      <v-card v-if="user">
        <v-card-title class="mb-4">
          User Information
          <v-spacer></v-spacer>
          <v-btn icon @click="userMenu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          <v-icon class="mr-2">mdi-account-circle</v-icon>
          {{ user.firstName }} {{ user.lastName }}
        </v-card-subtitle>
        <v-card-subtitle>
          <v-icon class="mr-2">mdi-account</v-icon>
          {{ user.username }}
        </v-card-subtitle>
        <v-card-subtitle>
          <v-icon class="mr-2">mdi-mail</v-icon>
          {{ user.email }}
        </v-card-subtitle>
        <v-card-subtitle>
          <v-btn outlined @click="statsMenu = true">
            <v-icon left>mdi-chart-bar</v-icon>
            See Statistics
          </v-btn>
        </v-card-subtitle>
        <v-card-title>Roles</v-card-title>
        <v-card-text>
          <v-chip-group
            v-for="(communityRole, community) in communityRoles()"
            v-model="userRoles[community]"
            :key="community"
            :mandatory="isActiveRole(communityRole)"
            color="primary"
          >
            <v-btn
              outlined
              :disabled="!userRoles[community]"
              @click="
                loggedUser.isSuperAdmin &&
                  chooseActiveRole(userRoles[community])
              "
              :class="isActiveRole(communityRole) ? 'success--text' : ''"
              rounded
              class="justify-start"
            >
              <v-icon left size="22" v-if="isActiveRole(communityRole)">
                mdi-checkbox-marked-circle
              </v-icon>
              {{ community }}
            </v-btn>
            <v-divider vertical class="mx-2"></v-divider>
            <v-chip
              v-for="role in communityRole"
              :key="role._id"
              :value="role._id"
              @click="isActiveRole(communityRole) && chooseActiveRole(role._id)"
            >
              {{ role.name }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="save" :loading="loading">
            <v-icon left color="success">mdi-content-save</v-icon>
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="statsMenu"
      @keydown.esc="statsMenu = false"
      width="800px"
      scrollable
      transition="dialog-top-transition"
    >
      <v-card v-if="user" :loading="loading">
        <v-card-title class="mb-4 elevation-5">
          Statistics
          <v-spacer></v-spacer>
          <v-card-subtitle>
            <v-icon class="mr-2">mdi-account-circle</v-icon>
            {{ user.firstName }} {{ user.lastName }}
          </v-card-subtitle>
          <v-card-subtitle>
            <v-icon class="mr-2">mdi-account</v-icon>
            {{ user.username }}
          </v-card-subtitle>
          <v-card-subtitle>
            <v-icon class="mr-2">mdi-mail</v-icon>
            {{ user.email }}
          </v-card-subtitle>
          <v-btn icon @click="statsMenu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6">
              <v-card>
                <v-card-title>Total Runs</v-card-title>
                <v-card-text class="display-1">{{ user.runs }}</v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="6">
              <v-card>
                <v-card-title>Total Submissions</v-card-title>
                <v-card-text class="display-1">
                  {{ user.submissions }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-select
                    v-if="!!stats"
                    v-model="statsType"
                    :items="['By Assignment', 'By Date']"
                    solo
                  ></v-select>
                </v-card-title>
                <bar-chart
                  v-if="!!stats"
                  v-show="statsType === 'By Date'"
                  :chartData="overTimeData"
                  :options="overTimeOptions"
                />
                <bar-chart
                  v-if="!!stats"
                  v-show="statsType === 'By Assignment'"
                  :height="assignments.length * 30 + 60"
                  :chartData="datasets"
                  :options="options"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BarChart from "@/components/core/BarChart.vue";
import EnabledDialog from "./EnabledDialog.vue";
import EnabledChip from "./EnabledChip.vue";

export default {
  name: "Users",
  components: {
    BarChart,
    EnabledDialog,
    EnabledChip
  },
  props: {
    search: String,
    currentView: String
  },
  created() {
    this.getUsers();
    this.getRoles();
  },
  watch: {
    // Fetch user stats every time the dialog opens
    async statsMenu(val) {
      if (val) {
        this.stats = null;
        this.statsType = "By Assignment";
        await this.getStatistics({ user_id: this.user._id });
        this.stats = this.statistics;
      }
    }
  },
  data: () => ({
    statsType: "By Assignment",
    statsMenu: false,
    stats: null,
    userMenu: false,
    user: null,
    userRoles: {},
    roleIDActive: null,
    fontSize: 12
  }),
  computed: {
    ...mapGetters({
      users: "getUsers",
      roles: "getRoles",
      currentRole: "getCurrentRole",
      currentCommunity: "getCurrentCommunity",
      loggedUser: "getLoggedUser",
      loading: "getLoading",
      statistics: "getStatistics"
    }),
    filteredUsers() {
      if (!this.search) return this.users;
      return this.users.filter(user => {
        const { firstName, lastName, username } = user;
        const search = this.search.toLowerCase();
        return (
          firstName.toLowerCase().includes(search) ||
          lastName.toLowerCase().includes(search) ||
          username.toLowerCase().includes(search)
        );
      });
    },
    headers() {
      return [
        {
          text: "Name",
          value: "name",
          width: "150px"
        },
        {
          text: "Username",
          value: "username",
          width: "110px"
        },
        {
          text: "Roles",
          value: "roles",
          sortable: false
        },
        {
          text: "Runs",
          value: "runs",
          width: "100px"
        },
        {
          text: "Submissions",
          value: "submissions",
          width: "140px"
        },
        {
          text: "Enabled",
          value: "enabled",
          sortable: false
        },
        {
          text: "Actions",
          value: "actions",
          width: "110px",
          sortable: false
        }
      ];
    },
    dark() {
      return this.$vuetify.theme.dark;
    },
    byAssignment() {
      return this.stats.byAssignment;
    },
    byDate() {
      return this.stats.byDate;
    },
    assignments() {
      return Object.keys(this.byAssignment);
    },
    byAssignmentRuns() {
      return Object.values(this.byAssignment).map(x => x.runs);
    },
    byAssignmentSubmissions() {
      return Object.values(this.byAssignment).map(x => x.submissions);
    },
    dates() {
      return Object.keys(this.byDate).map(x => x.slice(0, 10));
    },
    byDateRuns() {
      return Object.values(this.byDate).map(x => x.runs);
    },
    byDateSubmissions() {
      return Object.values(this.byDate).map(x => x.submissions);
    },
    overTimeData() {
      return {
        labels: this.dates,
        datasets: [
          {
            label: "Runs",
            data: this.byDateRuns,
            backgroundColor: "green"
          },
          {
            label: "Submissions",
            data: this.byDateSubmissions,
            backgroundColor: "red"
          }
        ]
      };
    },
    overTimeOptions() {
      return {
        legend: {
          display: true,
          position: "top",
          labels: {
            fontColor: this.dark ? "#fff" : "#000",
            fontSize: this.fontSize
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: false,
                suggestedMax:
                  Math.max(...this.byDateRuns, ...this.byDateSubmissions) + 1
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontSize: this.fontSize,
                fontColor: this.dark ? "#fff" : "#000"
              }
            }
          ]
        },
        plugins: {
          datalabels: {
            // Hide the labels that are 0
            display: ctx => ctx.dataset.data[ctx.dataIndex] !== 0,
            align: "end",
            anchor: "end",
            color: this.dark ? "#fff" : "#000",
            font: {
              size: this.fontSize,
              weight: "bold"
            }
          }
        }
      };
    },
    datasets() {
      return {
        labels: this.assignments,
        datasets: [
          {
            label: "Runs",
            data: this.byAssignmentRuns,
            backgroundColor: "green"
          },
          {
            label: "Submissions",
            data: this.byAssignmentSubmissions,
            backgroundColor: "red"
          }
        ]
      };
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "top",
          labels: {
            fontColor: this.dark ? "#fff" : "#000",
            fontSize: this.fontSize
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: false,
                suggestedMax:
                  Math.max(
                    ...this.byAssignmentRuns,
                    ...this.byAssignmentSubmissions
                  ) + 1
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontSize: this.fontSize,
                fontColor: this.dark ? "#fff" : "#000"
              }
            }
          ]
        },
        plugins: {
          datalabels: {
            // Hide the labels that are 0
            display: ctx => ctx.dataset.data[ctx.dataIndex] !== 0,
            align: "end",
            anchor: "end",
            color: this.dark ? "#fff" : "#000",
            font: {
              size: this.fontSize,
              weight: "bold"
            }
          }
        }
      };
    }
  },
  methods: {
    ...mapActions({
      getUsers: "getUsers",
      getRoles: "getRoles",
      patchUser: "patchUser",
      deleteUser: "deleteUser",
      getStatistics: "getStatistics"
    }),
    communityRoles(roleIDs) {
      const groupBy = key => roles =>
        roles.reduce((keyValue, role) => {
          const value = role[key].name;
          keyValue[value] = !roleIDs
            ? (keyValue[value] || []).concat(role)
            : role._id;
          return keyValue;
        }, {});
      const groupByCommunity = groupBy("communityID");
      return groupByCommunity(roleIDs || this.roles);
    },
    openUpdateUserMenu(user) {
      this.userMenu = true;
      this.user = Object.assign({}, user);
      this.roleIDActive = this.user.roleIDActive;
      this.userRoles = this.communityRoles(user.roleID);
    },

    chooseActiveRole(roleID) {
      if (!roleID) return;
      this.roleIDActive = roleID;
    },
    isActiveRole(communityRole) {
      return communityRole.some(role => role._id === this.roleIDActive);
    },
    roleChipColor(user, role) {
      if (user.roleIDActive === role._id) {
        return "success";
      }
    },
    async save() {
      await this.patchUser({
        id: this.user._id,
        data: {
          roleIDs: Object.values(this.userRoles),
          roleIDActive: this.roleIDActive
        }
      });
      this.userMenu = false;
    }
  }
};
</script>
<style scoped>
.clickable-row >>> tbody tr :hover {
  cursor: pointer;
}
</style>
