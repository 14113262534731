<template>
  <div id="enabled-chip">
    <v-chip
      large
      @click.stop="enabledDialog = true"
      class="my-2 body-2"
      outlined
    >
      <v-icon>mdi-clock</v-icon>
      <v-divider class="mx-2" vertical />
      <v-row no-gutters>
        <v-col cols="12">
          {{ extractDateTimeWithGmtOffset(patchObject.enabled.from) }}
        </v-col>
        <v-col cols="12">
          {{ extractDateTimeWithGmtOffset(patchObject.enabled.to) }}
        </v-col>
      </v-row>
      <v-divider class="mx-2" vertical />
      <v-icon v-if="patchObject.enabled.val" color="success">mdi-check</v-icon>
      <v-icon v-else color="error">mdi-close</v-icon>
    </v-chip>
    <enabled-dialog
      v-model="enabledDialog"
      :patchObject="patchObject"
      :patchCall="patchCall"
      :title="title"
    />
  </div>
</template>

<script>
import datesHelperMixin from "@/components/cms/mixins/datesHelperMixin.js";
import EnabledDialog from "@/components/cms/EnabledDialog.vue";

export default {
  name: "EnabledChip",
  components: {
    EnabledDialog
  },
  mixins: [datesHelperMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: "Enabled"
    },
    patchObject: {
      type: Object,
      required: true
    },
    patchCall: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    enabledDialog: false
  })
};
</script>
