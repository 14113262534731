<template>
  <div id="test-log">
    <v-toolbar flat height="80" class="title">
      <div class="text-no-wrap">Test cases</div>
      <v-chip
        small
        class="mx-3"
        :color="selectedItems.length > 0 ? 'success' : ''"
      />
      <v-divider vertical></v-divider>
      <v-subheader style="line-height: 20px">
        <span v-if="assignment.readOnly">Selected test cases</span>
        <span v-else>Select your test cases</span>
      </v-subheader>
      <v-spacer />
      <v-menu offset-x left nudge-left="10">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="hideParameters = !hideParameters">
            <v-list-item-icon>
              <v-icon>
                {{ hideParameters ? "mdi-eye" : "mdi-eye-off" }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ hideParameters ? "Show" : "Hide" }} parameter columns
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-data-table
      id="test-log-table"
      :value="selectedItems"
      @input="v => $emit('updateSelected', v)"
      :show-select="items && items.length > 0"
      :headers="filteredHeaders"
      :items="entries"
      :items-per-page="-1"
      item-key="runId"
      disable-sort
      hide-default-footer
    >
      <template v-slot:no-data>
        <v-alert class="my-2 white--text" type="warning">
          Looks like your test cases log is empty. Run some tests to fill it up!
        </v-alert>
      </template>
      <template
        v-if="blockUserInteraction"
        v-slot:[`header.data-table-select`]="{ props }"
      >
        <v-simple-checkbox
          :value="props.value"
          :indeterminate="props.indeterminate"
          disabled
        />
      </template>
      <template v-slot:[`item.runId`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on" v-if="item.runId" class="text-no-wrap">
              {{ item.runId.slice(-3) }}
            </div>
          </template>
          <span>{{ item.runId }}</span>
        </v-tooltip>
      </template>
      <template
        v-if="blockUserInteraction"
        v-slot:[`item.data-table-select`]="{ isSelected }"
      >
        <v-simple-checkbox :value="isSelected" disabled />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row justify="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="blockUserInteraction"
                @click="deleteRunLog(item)"
                outlined
                icon
                class="mr-2"
              >
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="viewTestDetails(item)" outlined icon>
                <v-icon color="primary">mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Report</span>
          </v-tooltip>
        </v-row>
      </template>
      <template v-slot:[`item.testingTechnique`]="{ item }">
        <v-select
          dense
          hide-details
          outlined
          :items="testingTechniques"
          :value="item.testingTechnique"
          :disabled="blockUserInteraction"
          @change="v => $emit('modifyLogItem', item.id, 'testingTechnique', v)"
        />
      </template>
      <template v-slot:[`item.expectedOutput`]="{ item }">
        <v-text-field
          readonly
          dense
          hide-details
          outlined
          :disabled="blockUserInteraction"
          :value="item.expectedOutput"
          @click="openExpectedOutput(item.id, item.expectedOutput)"
          @click:clear="
            v => $emit('modifyLogItem', item.id, 'expectedOutput', '')
          "
        />
      </template>
      <template v-slot:[`item.output`]="{ item }">
        <div class="d-flex flex-nowrap text-no-wrap align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                outlined
                :loading="loading[item.consoleId]"
                @click="openConsole(item.consoleId, item.error)"
                class="mr-2"
              >
                <v-icon size="20" v-if="!item.error">mdi-console</v-icon>
                <v-icon v-else color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Console</span>
          </v-tooltip>
          <div
            @click="openConsole(item.consoleId, item.error)"
            style="cursor: pointer; font-family: monospace; font-size: 12px"
          >
            <span v-if="item.output">
              {{
                item.output.length > 50
                  ? item.output.slice(0, 50) + "..."
                  : item.output
              }}
            </span>
            <span v-else-if="cachedOutput[item.consoleId]">
              {{
                `${
                  cachedOutput[item.consoleId].length > 50
                    ? cachedOutput[item.consoleId].slice(0, 50) + "..."
                    : cachedOutput[item.consoleId]
                }`
              }}
            </span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.testCasePassed`]="{ item }">
        <v-select
          :items="testCaseResults"
          :value="item.testCasePassed"
          :disabled="blockUserInteraction"
          @change="
            value => $emit('modifyLogItem', item.id, 'testCasePassed', value)
          "
          hide-details
          dense
          outlined
        />
      </template>
      <template
        v-for="parameterHeader of parameterHeaders"
        v-slot:[`item.${parameterHeader.text}`]="{ item }"
      >
        <div :key="parameterHeader.text">
          <div
            v-if="
              item[parameterHeader.text] &&
              item[parameterHeader.text].length > 20
            "
            class="font-weight-medium"
            style="cursor: pointer"
            @click="
              openFullParameterText(
                parameterHeader.text,
                item[parameterHeader.text]
              )
            "
          >
            {{ item[parameterHeader.text].slice(0, 20) }}...
          </div>
          <div v-else>{{ item[parameterHeader.text] }}</div>
        </div>
      </template>
    </v-data-table>
    <custom-dialog
      v-model="showExpectedOutputDialog"
      :readOnly="blockUserInteraction"
      :rows="2"
      title="Expected Output"
      :initialText="currentItemExpectedOutput"
      :canSave="true"
      @save="
        value => $emit('modifyLogItem', currentItemId, 'expectedOutput', value)
      "
      @close="showExpectedOutputDialog = false"
    />
    <custom-dialog
      v-model="showFullParameterText"
      :readOnly="true"
      :rows="2"
      :title="parameterHeader"
      :initialText="fullParameterText"
      @close="showFullParameterText = false"
    />
    <v-dialog v-model="consoleLog" max-width="700px">
      <v-card
        :loading="loading[consoleID] ? 'white' : false"
        dark
        color="black"
      >
        <v-card-title>
          {{ loading[consoleID] ? "Fetching output" : "Console output" }}
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="summedTimeout"
            :value="perc"
            rotate="90"
            size="32"
          >
            <span class="caption">{{ parseInt(perc) }}</span>
          </v-progress-circular>
          <v-btn v-if="showTryAgainButton" icon @click="tryAgainClicked()">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn icon @click="closeConsole">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container px-0>
            <pre>{{
              cachedOutput[consoleID] === null ||
              cachedOutput[consoleID] === undefined
                ? "Please wait..."
                : cachedOutput[consoleID]
            }}</pre>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="testDetails.dialog"
      scrollable
      transition="slide-y-transition"
      max-width="1200px"
    >
      <v-card>
        <v-card-title>
          Test report {{ testDetails.ref }}
          <v-spacer></v-spacer>
          <v-btn icon @click="testDetails.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" xl="6">
                <v-card flat>
                  <v-card-title class="primary--text">
                    <v-icon color="primary" left>mdi-import</v-icon>
                    Input
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col v-if="noTestParameters" cols="12">
                          <v-alert type="warning">
                            No test parameters in this test case
                          </v-alert>
                        </v-col>
                        <v-text-field
                          v-else
                          v-for="(value, field) of testDetails.parameters"
                          :key="field"
                          @click="openFullParameterText(field, value)"
                          :value="value"
                          :label="getLabel(field)"
                          hide-details
                          readonly
                          filled
                          class="ma-3"
                        />
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" xl="6">
                <v-card flat>
                  <v-card-title class="primary--text">
                    <v-icon color="primary" left>mdi-export</v-icon>
                    Output
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Cached output"
                            :loading="loading[testDetails.consoleId]"
                            :value="
                              testDetails.output ||
                              cachedOutput[testDetails.consoleId]
                            "
                            readonly
                            filled
                            @click="openConsole(testDetails.consoleId)"
                            hint="Click on the console button to refresh."
                            :persistent-hint="
                              cachedOutput[testDetails.consoleId] !== null &&
                              cachedOutput[testDetails.consoleId] !== undefined
                            "
                            append-icon="mdi-console"
                            @click:append="
                              openConsole(
                                testDetails.consoleId,
                                testDetails.error
                              )
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- <v-col cols="12">
                <v-card>
                  <v-card-title>
                    <v-icon left>mdi-file-document-edit-outline</v-icon>
                    Specifications
                  </v-card-title>
                  <v-card-text>
                    <v-form v-model="testDetails.valid" ref="testDetailsForm">
                      <v-container>
                        <v-row v-if="!testDetails.valid">
                          <v-col cols="12">
                            <v-alert type="info">
                              Make sure to fill in the log all the required
                              fields to submit this test case
                            </v-alert>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              label="Expected Output"
                              :value="testDetails.expectedOutput"
                              :rows="1"
                              auto-grow
                              filled
                              readonly
                              :rules="required"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Testing Technique"
                              :value="testDetails.testingTechnique"
                              filled
                              readonly
                              :rules="required"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Test Passed"
                              :value="
                                testDetails.testCasePassed === undefined ||
                                testDetails.testCasePassed === null
                                  ? ''
                                  : testDetails.testCasePassed
                                  ? 'Pass'
                                  : 'Fail'
                              "
                              filled
                              readonly
                              :rules="required"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card> 
              </v-col> -->
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Assignment from "@/services/AssignmentService";
import CustomDialog from "../core/CustomDialog.vue";

export default {
  components: { CustomDialog },
  props: {
    assignment: Object,
    components: Array,
    headers: Array,
    items: Array,
    selectedItems: Array,
    color: Object,
    assignmentRunCounter: Number
  },
  data: () => ({
    loading: {},
    testDetails: {
      ref: "",
      valid: false,
      dialog: false,
      parameters: null,
      consoleId: null,
      expectedOutput: null,
      testingTechnique: null,
      testCasePassed: null
    },
    required: [v => !!v || "Required"],
    cachedOutput: {},
    consoleID: "",
    consoleLog: false,
    fibonacci: [500, 500, 1000, 1500, 2500, 3500, 6000],
    fibonacciTimeouts: [],
    showTryAgainButton: false,
    summedTimeout: 0,
    timeouts: [],
    rowTimeout: {},
    parameterHeader: "",
    fullParameterText: "",
    showFullParameterText: false,
    showExpectedOutputDialog: false,
    testingTechniques: ["BVA", "EP", "Other"],
    testCaseResults: [
      { text: "Pass", value: true },
      { text: "Fail", value: false }
    ],
    currentItemExpectedOutput: "",
    currentItemId: null,
    hideParameters: false
  }),
  computed: {
    noTestParameters() {
      const { parameters } = this.testDetails;
      return parameters && Object.keys(parameters).length === 0;
    },
    filteredHeaders() {
      if (!this.hideParameters) return this.headers;
      return this.headers.filter(header => !header.text.includes("Parameter"));
    },
    blockUserInteraction() {
      return this.assignmentRunCounter > 0 || this.assignment.readOnly;
    },
    perc() {
      return (
        (this.summedTimeout * 100) /
        [...this.fibonacci].reduce((a, b) => a + b, 0)
      );
    },
    entries() {
      const items = [...this.items];
      for (let row_i = 0; row_i < items.length; row_i++) {
        // Transform only parameters cells
        for (let col_i = 5; col_i < this.headers.length - 1; col_i++) {
          const l = this.headers[col_i + 1].value;
          if (items[row_i][l] === undefined) items[row_i][l] = "NULL";
          if (items[row_i][l] === null || items[row_i][l] === "")
            items[row_i][l] = "EMPTY";
        }
      }
      this.enableItalicFont();
      return items;
    },
    parameterHeaders() {
      return this.headers.filter(header =>
        header.text.toLowerCase().includes("parameter")
      );
    }
  },
  methods: {
    getLabel(field) {
      return this.headers.find(h => h.value === field)?.text || field;
    },
    viewTestDetails(row) {
      // Open the dialog
      this.testDetails.dialog = true;
      this.testDetails.ref = row.id + "." + row.runId.slice(-3);
      this.testDetails.consoleId = row.consoleId;
      this.testDetails.expectedOutput = row.expectedOutput;
      this.testDetails.testingTechnique = row.testingTechnique;
      this.testDetails.testCasePassed = row.testCasePassed;
      this.testDetails.output = row.output;
      // Fetch the console output automatically
      if (
        this.cachedOutput[this.testDetails.consoleId] === null ||
        this.cachedOutput[this.testDetails.consoleId] === undefined
      ) {
        this.restoreFibonacci();
        this.getConsoleOutput(this.testDetails.consoleId);
      }
      this.testDetails.parameters = Object.keys(row)
        .filter(key => key.startsWith("Parameter") && row[key] !== "NULL")
        .reduce((obj, key) => {
          obj[key] = row[key];
          return obj;
        }, {});
      // Validate the form
      // this.$nextTick(() => {
      //   this.$refs.testDetailsForm.validate();
      // });
    },
    openExpectedOutput(id, text) {
      this.showExpectedOutputDialog = true;
      this.currentItemExpectedOutput = text;
      this.currentItemId = id;
    },
    openFullParameterText(header, text) {
      this.parameterHeader = header;
      this.fullParameterText = text;
      this.showFullParameterText = true;
    },
    tryAgainClicked() {
      this.restoreFibonacci();
      this.getConsoleOutput(this.consoleID);
    },
    restoreFibonacci() {
      this.showTryAgainButton = false;
      this.fibonacciTimeouts = [...this.fibonacci];
    },
    restoreTimeouts() {
      this.timeouts.forEach(x => clearTimeout(x));
      this.timeouts = [];
      this.summedTimeout = 0;
    },
    async deleteRunLog(item) {
      if (!confirm("Are you sure you want to delete this row ?")) return;
      const runLogID = item.runId;
      let text = "";
      let color = "";
      try {
        await Assignment.deleteRunLog(this.assignment.id, runLogID);
        text = "Test log deleted.";
        color = "success";
        this.$emit("deleteLog", this.assignment.id, runLogID);
      } catch (err) {
        text =
          err?.response?.data?.message ||
          "Test log could not be deleted. Please try again later.";
        color = "error";
      } finally {
        this.$store.commit("alert", {
          text,
          color,
          timeout: 8000
        });
      }
    },
    async getConsoleOutput(console_id, output = "", running = true) {
      if (output === "" && running) {
        try {
          this.$set(this.loading, console_id, true);
          const res = await Assignment.getConsole(
            this.assignment.id,
            console_id
          );
          running = res.data.running;
          output = res.data.error
            ? res.data.error
            : res.data.log.toString().trim();
          if (this.fibonacciTimeouts.length > 0) {
            const curr_timeout = this.fibonacciTimeouts.shift();
            this.timeouts.push(
              setTimeout(() => {
                this.summedTimeout += curr_timeout;
                this.getConsoleOutput(console_id, output, running);
              }, curr_timeout)
            );
          } else {
            this.showTryAgainButton = true;
            this.cachedOutput[console_id] = "The executable is still running.";
            this.restoreTimeouts();
          }
        } catch (e) {
          this.restoreTimeouts();
          this.$set(this.loading, console_id, false);
          this.cachedOutput[console_id ] = `An error occurred. Please try again later.`;
          return false;
        }
      } else {
        this.restoreTimeouts();
        this.$set(this.loading, console_id, false);
        this.cachedOutput[console_id] = output;
        return true;
      }
    },
    openConsole(console_id, error) {
      // Save the console_id
      this.consoleID = console_id;
      // Open the console
      this.restoreFibonacci();
      this.consoleLog = true;
      if (console_id) {
        // Return the promise
        return this.getConsoleOutput(console_id);
      }
      if (error) {
        // Update the output with the error message
        this.cachedOutput[console_id] = error;
        // Return failed promise
        return -1;
      }
    },
    closeConsole() {
      this.consoleLog = false;
    },
    getDOMRows() {
      const table = document.getElementById("test-log-table");
      return table.getElementsByTagName("tr");
    },
    enableItalicFont() {
      this.$nextTick(() => {
        const rows = this.getDOMRows();
        for (const row of rows) {
          const cells = row.getElementsByTagName("td");
          for (let col = 2; col < cells.length; col++) {
            try {
              const val = cells[col].childNodes[0].childNodes[0].innerHTML;
              // TODO: What if input parameter is EMPTY or NULL ?
              if (val === "EMPTY" || val === "NULL")
                cells[col].classList.add("font-italic");
            } catch {
              continue;
            }
          }
        }
      });
    },
    cleanColorTimeout(id, row) {
      // Clean color, animation and clear timeout
      const { classList } = row;
      if (this.rowTimeout[id]) {
        this.$vuetify.theme.dark
          ? classList.remove("fade-dark")
          : classList.remove("fade-light");
        classList.remove(this.rowTimeout[id].color);
        clearTimeout(this.rowTimeout[id].timeout);
        delete this.rowTimeout[id];
      }
    },
    setColorTimeout(row, color, seconds) {
      const { classList } = row;
      const dark = this.$vuetify.theme.dark;
      return setTimeout(() => {
        dark ? classList.remove("fade-dark") : classList.remove("fade-light");
        classList.remove(color);
        classList.remove("test-log-run-selection");
      }, seconds * 1000);
    },
    setColor(row, color) {
      // Show row selection
      const { classList } = row;
      this.$vuetify.theme.dark
        ? classList.add("fade-dark")
        : classList.add("fade-light");
      classList.add(color);
      classList.add("test-log-run-selection");
    },
    showRowSelection(item) {
      const { id, color } = item;
      if (!id) return;
      // TODO: Get output automatically
      // const { consoleId } = this.items[id - 1];
      // Load console output
      // this.restoreFibonacci();
      // this.getConsoleOutput(consoleId, "", true);
      const rows = this.getDOMRows();
      const row = rows[id];
      // Restore color
      this.cleanColorTimeout(id, row);
      // Set new color
      this.setColor(row, color);
      // Set timeout for 10 seconds
      const timeout = this.setColorTimeout(row, color, 3);
      // Store timeout and color
      this.rowTimeout[id] = {
        color,
        timeout
      };
    }
  },
  beforeDestroy() {
    // Clear all timeouts
    Object.keys(this.rowTimeout).forEach(key => {
      clearTimeout(this.rowTimeout[key].timeout);
      delete this.rowTimeout[key];
    });
  },
  watch: {
    color: {
      immediate: true,
      deep: true,
      handler(row) {
        if (row) {
          this.$nextTick(() => {
            this.showRowSelection(row);
          });
        }
      }
    }
  }
};
</script>
<style>
/* https://stackoverflow.com/questions/248011/how-do-i-wrap-text-in-a-pre-tag */
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.fade-light {
  -webkit-animation: light 3s 1; /* Safari 4+ */
  -moz-animation: light 3s 1; /* Fx 5+ */
  -o-animation: light 3s 1; /* Opera 12+ */
  animation: light 3s 1; /* IE 10+, Fx 29+ */
}
@keyframes light {
  100% {
    background-color: white;
  }
}
@-moz-keyframes light {
  100% {
    background-color: white;
  }
}
@-webkit-keyframes light {
  100% {
    background-color: white;
  }
}
@-o-keyframes light {
  100% {
    background-color: white;
  }
}
@-ms-keyframes light {
  100% {
    background-color: white;
  }
}

.fade-dark {
  -webkit-animation: dark 3s 1; /* Safari 4+ */
  -moz-animation: dark 3s 1; /* Fx 5+ */
  -o-animation: dark 3s 1; /* Opera 12+ */
  animation: dark 3s 1; /* IE 10+, Fx 29+ */
}
@keyframes dark {
  100% {
    background-color: #424242;
  }
}
@-moz-keyframes dark {
  100% {
    background-color: #424242;
  }
}
@-webkit-keyframes dark {
  100% {
    background-color: #424242;
  }
}
@-o-keyframes dark {
  100% {
    background-color: #424242;
  }
}
@-ms-keyframes dark {
  100% {
    background-color: #424242;
  }
}
</style>
