<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-switch
          v-model="localRandomAccessRequirements.applied"
          inset
          :label="switchLabel"
          class="pt-0"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Whitebox"
          dense
          rounded
          filled
          number
          v-model="localRandomAccessRequirements.whitebox"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Blackbox"
          dense
          rounded
          filled
          number
          v-model="localRandomAccessRequirements.blackbox"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      localRandomAccessRequirements: {
        applied: false,
        whitebox: 1,
        blackbox: 1
      }
    };
  },
  props: {
    switchLabel: {
      type: String,
      required: false,
      default: "Apply random access"
    },
    randomAccessRequirements: {
      type: Object,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit("input", false);
    }
  },
  computed: {},
  created() {
    Object.assign(
      this.localRandomAccessRequirements,
      this.randomAccessRequirements
    );
  },
  watch: {
    localRandomAccessRequirements: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  }
};
</script>
