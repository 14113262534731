<template>
    <v-container
        class="mt-2"
        style="max-height: calc(100vh - 450px) !important; overflow-y: auto"
    >
        <v-row v-for="role of loggedUser.roleID" :key="role._id">
            <v-chip
                @click="updateActiveRole(role)"
                :color="loggedUser.roleIDActive === role._id ? 'success' : ''"
                outlined
                class="mt-3"
            >
                <v-icon
                    v-if="loggedUser.roleIDActive === role._id"
                    color="success"
                    left
                    size="22"
                >
                    mdi-checkbox-marked-circle
                </v-icon>
                <v-icon size="22" left v-else>
                    mdi-checkbox-blank-circle-outline
                </v-icon>
                <span>
                    {{ role.communityID.name }}
                </span>
                <v-divider vertical class="mx-2"></v-divider>
                <v-subheader class="px-0">
                    {{ role.name }}
                </v-subheader>
            </v-chip>
        </v-row>
    </v-container>
</template>
<script>
import PreferencesService from '@/services/PreferencesService';

export default {
    computed: {
        loggedUser() {
            return this.$store.getters.getLoggedUser;
        },
    },
    methods: {
        async updateActiveRole(role) {
            await PreferencesService.updateUserActiveProfile({
                roleIDActive: role._id,
            });
            // TODO: Refresh data without reloading the page
            this.$router.push('/');
            this.$router.go();
        },
    },
};
</script>