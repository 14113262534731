<template>
  <v-dialog
    :value="value"
    max-width="1280px"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card v-if="item">
      <v-card-title class="display-1">
        <v-icon class="mr-2" size="42" color="primary">mdi-clock</v-icon>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col sm="12" lg="6">
            <v-card flat>
              <v-card-title>
                <v-text-field
                  label="From"
                  dense
                  disabled
                  :value="getFrom()"
                ></v-text-field>
              </v-card-title>
              <v-card-text>
                <v-date-picker
                  class="elevation-0"
                  readonly
                  :max="formatDate(forever)"
                  :value="dateFrom"
                ></v-date-picker>
                <v-time-picker
                  class="elevation-0"
                  format="24hr"
                  readonly
                  :value="timeFrom"
                ></v-time-picker>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="12" lg="6">
            <v-card flat>
              <v-card-title>
                <v-text-field
                  label="To"
                  dense
                  disabled
                  :value="getTo()"
                ></v-text-field>
              </v-card-title>
              <v-card-text>
                <v-date-picker
                  class="elevation-0"
                  readonly
                  :max="formatDate(forever)"
                  :color="clockColor"
                  :value="dateTo"
                ></v-date-picker>
                <v-time-picker
                  class="elevation-0"
                  format="24hr"
                  readonly
                  :color="clockColor"
                  :value="timeTo"
                ></v-time-picker>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      forever: new Date(2100, 0, 2)
    };
  },
  props: {
    value: {
      type: Boolean
    },
    item: {
      type: Object
    }
  },
  methods: {
    getFrom() {
      return new Date(this.item.enabled.from);
    },
    getTo() {
      return new Date(this.item.enabled.to);
    },
    formatTime(time) {
      return time.toLocaleTimeString().split(" ")[0];
    },
    formatDate(date) {
      return date.toISOString().split("T")[0];
    },
    closeDialog() {
      this.$emit("input", false);
    }
  },
  computed: {
    timeFrom() {
      return this.item && this.formatTime(this.getFrom());
    },
    timeTo() {
      return this.item && this.formatTime(this.getTo());
    },
    dateFrom() {
      return this.item && this.formatDate(this.getFrom());
    },
    dateTo() {
      return this.isForever
        ? this.formatDate(this.forever)
        : this.item && this.formatDate(this.getTo());
    },
    isForever() {
      return this.getTo() > this.forever;
    },
    clockColor() {
      return !this.isForever ? "primary" : "secondary";
    }
  }
};
</script>
<style>
.v-picker {
  border-radius: 0% !important;
}
.v-picker__title {
  max-height: 88px;
}
</style>
