<template>
    <v-container id="dashboard" fluid>
        <v-row>
            <v-toolbar id="dashboard-toolbar" flat>
                <v-icon left>mdi-chart-line</v-icon>
                <v-toolbar-title>
                    <strong>
                        {{ currentCommunity }}
                    </strong>
                    community statistics
                </v-toolbar-title>
            </v-toolbar>
            <v-col class="px-0" cols="12">
                <v-row>
                    <v-col cols="12" lg="3">
                        <v-card class="elevation-1" @click="goToTab('Users')">
                            <v-card-title>
                                <v-icon left>mdi-account-group</v-icon>
                                Users
                            </v-card-title>
                            <v-card-text class="display-1">
                                <v-row>
                                    <v-col>
                                        <v-card class="elevation-0">
                                            <v-card-title class="body-1">
                                                <v-icon left>
                                                    mdi-account-check
                                                </v-icon>
                                                VERIFIED
                                            </v-card-title>
                                            <v-card-text class="display-1">
                                                {{ totalUsers }}
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="9">
                        <v-card
                            class="elevation-1"
                            @click="
                                loggedUser.isSuperAdmin
                                    ? goToTab('Communities')
                                    : undefined
                            "
                        >
                            <v-card-title>
                                <v-icon left>mdi-account-group-outline</v-icon>
                                Community
                            </v-card-title>
                            <v-card-text class="display-1">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        lg="3"
                                        v-for="(value, key) of totalRoles"
                                        :key="key"
                                    >
                                        <v-card class="elevation-0">
                                            <v-card-title class="body-1">
                                                <v-icon left>
                                                    {{ icons[key] }}
                                                </v-icon>
                                                {{ key }}
                                            </v-card-title>
                                            <v-card-text class="display-1">
                                                {{ value }}
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="px-0" cols="12">
                <v-row>
                    <v-col cols="12" lg="3">
                        <v-card
                            class="elevation-1"
                            @click="goToTab('Custom Folders')"
                        >
                            <v-card-title>
                                <v-icon left>mdi-book-outline</v-icon>
                                Exams
                            </v-card-title>
                            <v-card-text class="display-1">
                                {{ totalExams }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-card
                            class="elevation-1"
                            @click="goToTab('Assignments')"
                        >
                            <v-card-title>
                                <v-icon left>mdi-bug</v-icon>
                                Assignments
                            </v-card-title>
                            <v-card-text class="display-1">
                                {{ totalAssignments }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-card class="elevation-1" @click="() => {}">
                            <v-card-title>
                                <v-icon left>mdi-play-speed</v-icon>
                                Runs
                            </v-card-title>
                            <v-card-text class="display-1">
                                {{ totalRuns }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-card class="elevation-1" @click="() => {}">
                            <v-card-title>
                                <v-icon left>mdi-upload-outline</v-icon>
                                Submissions
                            </v-card-title>
                            <v-card-text class="display-1">
                                {{ totalSubmissions }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="px-0">
                <v-card class="elevation-1">
                    <v-toolbar flat>
                        <v-icon left>mdi-chart-bar</v-icon>
                        <v-toolbar-title>Users daily activity</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" text class="mr-4">
                                    <v-icon color="primary" left>
                                        mdi-calendar
                                    </v-icon>
                                    {{
                                        period[0].split('-').reverse().join('/')
                                    }}
                                    {{
                                        period[1]
                                            ? ' - ' +
                                              period[1]
                                                  .split('-')
                                                  .reverse()
                                                  .join('/')
                                            : ''
                                    }}
                                    <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-date-picker
                                    v-model="period"
                                    :max="
                                        new Date().toISOString().substr(0, 10)
                                    "
                                    range
                                    year-icon="mdi-calendar-blank"
                                ></v-date-picker>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        @click="menu = false"
                                        color="primary"
                                    >
                                        OK
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-toolbar>
                    <v-card-text>
                        <Chart
                            v-if="!loading"
                            :height="320"
                            :chartData="datasets"
                            :options="options"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Chart from '../core/Chart.vue';

export default {
    name: 'Dashboard',
    components: {
        Chart,
    },
    data: () => ({
        icons: {
            OWNER: 'mdi-account-star',
            ADMIN: 'mdi-account-tie',
            MODERATOR: 'mdi-account-cog',
            USER: 'mdi-account',
        },
        menu: false,
        period: [
            // DEFAULT DATES
            // FROM:
            new Date(new Date().setDate(new Date().getDate() - 7))
                .toISOString()
                .split('T')[0],
            // TO
            new Date().toISOString().split('T')[0],
        ],
    }),
    props: {
        fontSize: {
            type: Number,
            default: 12,
        },
    },
    methods: {
        goToTab(tabName) {
            if (this.currentRole === 'MODERATOR') return undefined;
            this.$emit('tabChange', tabName);
        },
    },
    computed: {
        ...mapGetters({
            dashboard: 'getDashboard',
            currentRole: 'getCurrentRole',
            currentCommunity: 'getCurrentCommunity',
            loading: 'getLoading',
            users: 'getUsers',
            assignments: 'getAssignments',
            loggedUser: 'getLoggedUser',
        }),
        datasets() {
            return {
                labels: this.dates,
                datasets: [
                    {
                        label: 'Runs',
                        data: this.runsOverTime,
                        backgroundColor: 'green',
                    },
                    {
                        label: 'Submissions',
                        data: this.submisssionsOverTime,
                        backgroundColor: 'red',
                    },
                ],
            };
        },
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                fontSize: 14,
                                fontColor: this.dark ? '#fff' : '#000',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                                suggestedMax: Math.max(
                                    ...this.datasets.datasets.map(
                                        (d) => Math.max(...d.data) * 1.1,
                                    ),
                                ),
                            },
                        },
                    ],
                },
                plugins: {
                    datalabels: {
                        // Hide the labels that are 0
                        display: (ctx) => ctx.dataset.data[ctx.dataIndex] !== 0,
                        align: 'end',
                        anchor: 'end',
                        color: this.dark ? '#fff' : '#000',
                        font: {
                            size: this.fontSize,
                            weight: 'bold',
                        },
                    },
                },
            };
        },
        dark() {
            return this.$vuetify.theme.dark;
        },
        dates() {
            return Object.keys(this.dashboard.overTime).map((x) =>
                x.slice(0, 10),
            );
        },
        runsOverTime() {
            return Object.values(this.dashboard.overTime).map((x) => x.runs);
        },
        submisssionsOverTime() {
            return Object.values(this.dashboard.overTime).map(
                (x) => x.submissions,
            );
        },
        totalUsers() {
            return this.users.length;
        },
        totalRoles() {
            return (
                this.dashboard.totalRoles || {
                    OWNER: 0,
                    ADMIN: 0,
                    MODERATOR: 0,
                    USER: 0,
                }
            );
        },
        totalAssignments() {
            return this.assignments.length;
        },
        totalExams() {
            return '-';
        },
        totalRuns() {
            return this.dashboard.totalRuns || 0;
        },
        totalSubmissions() {
            return this.dashboard.totalSubmissions || 0;
        },
    },
    created() {
        this.$store.dispatch('getDashboard', { dates: this.period });
    },
    watch: {
        period(dates) {
            const [from, to] = dates;
            if (new Date(from) > new Date(to)) this.period.reverse();
        },
        menu(val) {
            if (!val)
                this.$store.dispatch('getDashboard', { dates: this.period });
        },
    },
};
</script>
<style scoped>
#dashboard {
    user-select: none;
}
#dashboard-toolbar {
    position: sticky;
    top: 0;
    z-index: 1;
}
</style>