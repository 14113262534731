<template>
  <v-dialog
    :value="value"
    persistent
    @keydown.esc="closeDialog()"
    max-width="85vw"
  >
    <v-card>
      <v-card-title class="display-1">
        {{
          itemIsNew ? "New invitation link" : `Edit invitation link parameters`
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container fluid class="py-2">
          <v-card elevation="4">
            <v-card-text class="px-0 py-0">
              <v-container fluid>
                <v-row justify="center">
                  <v-col cols="12" md="4" class="mt-4">
                    <v-select
                      v-model="localLink.roleID"
                      label="Role"
                      :items="availableRoles()"
                      item-value="_id"
                      hide-details
                      chips
                      rounded
                      filled
                    >
                      <template v-slot:item="{ item }">
                        {{ item.communityID.name }}
                        <v-chip class="mx-2">
                          {{ item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:selection="{ item }">
                        {{ item.communityID.name }}
                        <v-chip class="mx-2">
                          {{ item.name }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="8" class="py-0 mt-7">
                    <v-autocomplete
                      multiple
                      :items="assignmentCustomGroups"
                      item-text="name"
                      item-value="_id"
                      v-model="localLink.assignmentCustomGroupIDs"
                      label="Assignment custom groups"
                      filled
                      hide-details
                      rounded
                      chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <enabled-new
                      v-if="value"
                      v-model="localLink.enabled"
                      switchLabel="Link enabled"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveChanges()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import EnabledNew from "./EnabledNew.vue";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  components: {
    EnabledNew
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    itemIsNew: {
      type: Boolean,
      required: true
    },
    link: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    localLink: undefined
  }),
  computed: {
    ...mapGetters({
      loggedUser: "getLoggedUser",
      assignmentCustomGroups: "getAssignmentCustomGroups",
      roles: "getRoles"
    }),
    loggedUserActiveCommunityName() {
      return this.loggedUser.roleID.filter(role => {
        return role._id === this.loggedUser.roleIDActive;
      })[0].communityID.name;
    }
  },
  methods: {
    ...mapActions({
      getAssignmentCustomGroups: "getAssignmentCustomGroups",
      addLink: "addLink",
      patchLink: "patchLink"
    }),
    availableRoles() {
      if (!this.roles) return [];
      if (this.loggedUser.isSuperAdmin) return this.roles;
      return this.roles.slice(
        this.roles.findIndex(i => i.name === this.currentRole) + 1,
        this.roles.length
      );
    },
    closeDialog() {
      this.$emit("input", false);
    },
    async saveChanges() {
      if (this.itemIsNew) {
        await this.addLink(this.localLink);
      } else {
        await this.patchLink({
          id: this.localLink._id,
          data: {
            roleID: this.localLink.roleID,
            assignmentCustomGroupIDs: this.localLink.assignmentCustomGroupIDs,
            enabled: this.localLink.enabled
          }
        });
      }
      this.$emit("input", false);
      this.$emit("refresh");
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.localLink = cloneDeep(this.link);
        this.getAssignmentCustomGroups();
      }
    }
  },
  created() {
    this.localLink = cloneDeep(this.link);
    this.getAssignmentCustomGroups();
  }
};
</script>
