<template>
  <v-dialog
    :value="value"
    persistent
    @keydown.esc="closeDialog()"
    max-width="85vw"
  >
    <v-card>
      <v-card-title class="display-1">
        {{
          itemIsNew
            ? "New custom folder"
            : `Edit ${assignmentCustomGroup.name} parameters`
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container fluid class="py-2">
          <v-card elevation="4">
            <v-card-text class="px-0 py-0">
              <v-form ref="form">
                <v-container fluid>
                  <v-row justify="center">
                    <v-col cols="3" class="py-0">
                      <v-text-field
                        v-model="localAssignmentCustomGroup.name"
                        dense
                        filled
                        rounded
                        class="mt-6"
                        label="Name"
                        :rules="[rules.required, rules.maxLength(48)]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="9">
                      <enabled-new
                        v-if="value"
                        v-model="localAssignmentCustomGroup.enabled"
                        switchLabel="Folder enabled"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-container>
        <v-container fluid class="py-2">
          <v-card elevation="4">
            <v-card-text class="px-0 py-0">
              <users-assignments-pools
                v-if="value"
                :selectedAssignments="assignmentCustomGroup.assignmentsIDs"
                :selectedUsers="assignmentCustomGroup.usersIDs"
                @updateSelectedAssignments="
                  v => (localAssignmentCustomGroup.assignmentsIDs = v)
                "
                @updateSelectedUsers="
                  v => (localAssignmentCustomGroup.usersIDs = v)
                "
              />
            </v-card-text>
          </v-card>
        </v-container>
        <v-container fluid class="py-2">
          <v-card elevation="4">
            <v-card-text class="px-0 py-0">
              <v-container fluid>
                <v-row justify="center">
                  <v-col cols="3" class="py-0">
                    <v-text-field
                      label="Creator"
                      :value="loggedUser.fullName"
                      class="mt-6"
                      dense
                      filled
                      rounded
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-text-field
                      label="Community"
                      :value="loggedUserActiveCommunityName"
                      class="mt-6"
                      dense
                      filled
                      rounded
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <random-access-requirements
                      v-if="value"
                      :randomAccessRequirements="
                        localAssignmentCustomGroup.randomAccessRequirements
                      "
                      @input="
                        v =>
                          (localAssignmentCustomGroup.randomAccessRequirements =
                            v)
                      "
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveChanges()">Save</v-btn>
      </v-card-actions>
    </v-card>
    <pop-up
      v-model="popup"
      :color="$vuetify.theme.dark ? 'dark' : 'white'"
      titleText="Warning"
      text="Please note that editing a custom group may have an impact on the access to the folder. Review the impact from different use cases below and confirm to continue."
      icon="mdi-alert"
      iconColor="warning"
      :dividerColor="$vuetify.theme.dark ? `#e27e36` : `#FFC107`"
      type="editCustomGroup"
      confirmationText="Confirm"
      @confirm="handlePopupConfiremd()"
    >
      <v-container>
        <template>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h2>Example Use Case Scenarios</h2>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li v-for="(useCase, index) in useCases" :key="index">
                      <strong v-text="useCase.title"></strong>
                      <div v-text="useCase.description"></div>
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </pop-up>
  </v-dialog>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import EnabledNew from "./EnabledNew.vue";
import UsersAssignmentsPools from "./UsersAssignmentsPools.vue";
import RandomAccessRequirements from "./RandomAccessRequirements.vue";
import PopUp from "../core/PopUp.vue";
import { rules } from "@/utils/rules.js";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  components: {
    EnabledNew,
    UsersAssignmentsPools,
    RandomAccessRequirements,
    PopUp
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    itemIsNew: {
      type: Boolean,
      required: true
    },
    assignmentCustomGroup: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    localAssignmentCustomGroup: undefined,
    rules,
    popup: false,
    popupConfirmed: false,
    useCases: [
      {
        title: "Use Case 1:",
        description: `Initially, the teacher sets the folder to randomly distribute 3 whitebox and 2 blackbox assignments.
          Later, he/she decides to change this to 2 whitebox and 2 blackbox assignments.
          This action will delete all current access to the folder and create new one using the new requirements. 
          The assignments in the folder to which a user has access could change entirely.`
      },
      {
        title: "Use Case 2:",
        description: `The folder has random distribution enabled with 3 whitebox and 2 blackbox assignments per user.
          Student John encounters an issue where one of his whitebox assignments doesn't compile. The teacher can remove this problematic assignment from the folder.
          On save, the system replaces John's access to the removed assignment with access to another whitebox assignment from the folder's assignments pool.
          The other assignments in the folder to which John has access will remain unchanged.`
      },
      {
        title: "Use Case 3:",
        description: `When a teacher adds assignments to a folder, all users with access to the folder receive the new assignments, provided that random distribution is not applied.
        If random distribution is in effect, current access remains unchanged and the new assignments are only used when granting access to new users for the folder.`
      }
    ]
  }),
  computed: {
    ...mapGetters({ loggedUser: "getLoggedUser" }),
    loggedUserActiveCommunityName() {
      return this.loggedUser.roleID.filter(role => {
        return role._id === this.loggedUser.roleIDActive;
      })[0].communityID.name;
    }
  },
  methods: {
    ...mapActions({
      addAssignmentCustomGroup: "addAssignmentCustomGroup",
      patchAssignmentCustomGroup: "patchAssignmentCustomGroup"
    }),
    closeDialog() {
      this.$emit("input", false);
    },
    async handlePopupConfiremd() {
      this.popup = false;
      this.popupConfirmed = true;
      await this.saveChanges();
    },
    async saveChanges() {
      if (!this.$refs.form.validate()) return;
      if (this.itemIsNew) {
        await this.addAssignmentCustomGroup(this.localAssignmentCustomGroup);
      } else if (!this.popupConfirmed) {
        this.popup = true;
        return;
      } else {
        await this.patchAssignmentCustomGroup({
          id: this.assignmentCustomGroup._id,
          data: this.localAssignmentCustomGroup
        });
        this.popupConfirmed = false;
      }
      this.$emit("input", false);
      this.$emit("refresh");
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.localAssignmentCustomGroup = cloneDeep(this.assignmentCustomGroup);
      }
    }
  },
  created() {
    this.localAssignmentCustomGroup = cloneDeep(this.assignmentCustomGroup);
  }
};
</script>
