<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-toolbar class="headline" flat>
          Custom Folders
          <v-spacer></v-spacer>
          <v-btn
            @click="openAssignmentCustomGroupDialog()"
            color="primary"
            large
            rounded
          >
            Create
          </v-btn>
        </v-toolbar>
        <v-alert v-show="!assignmentCustomGroups.length" color="warning">
          <v-icon>warning</v-icon>
          No data
        </v-alert>
      </v-col>
    </v-row>
    <v-container v-if="currentView === 'Grid'" fluid>
      <v-row>
        <v-col
          v-for="item of filteredAssignmentCustomGroups"
          :key="item._id"
          :cols="12"
          :md="6"
          :lg="4"
          :xl="3"
        >
          <v-card height="100%">
            <v-card-title>
              {{ item.name }}
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                large
                icon
                @click.stop="openAssignmentCustomGroupDialog(item)"
              >
                <v-icon>mdi-pen</v-icon>
              </v-btn>
              <v-btn
                color="red"
                large
                icon
                @click.stop="deleteAssignmentCustomGroup(item._id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-7">
              <v-container>
                <v-row>
                  <v-chip class="mr-2">
                    <v-icon left>mdi-account</v-icon>
                    Users with access: {{ item.usersIDs.length }}
                  </v-chip>
                  <v-chip class="mr-2">
                    <v-icon left>mdi-file-document</v-icon>
                    Assignments pool: {{ item.assignmentsIDs.length }}
                  </v-chip>
                </v-row>
                <v-row>
                  <v-chip class="mt-3 mr-2">
                    <v-icon left>mdi-dice-multiple</v-icon>
                    Random access:
                    <v-icon v-if="item.randomAccessRequirements.applied">
                      mdi-check
                    </v-icon>
                    <v-icon v-else>mdi-close</v-icon>
                    <v-divider class="mx-2" vertical />
                    Whitebox {{ item.randomAccessRequirements.whitebox }},
                    Blackbox
                    {{ item.randomAccessRequirements.blackbox }}
                  </v-chip>
                </v-row>
                <v-row>
                  <enabled-chip
                    :title="`Enabled for ${item.name}`"
                    :patchObject="{
                      _id: item._id,
                      enabled: item.enabled
                    }"
                    :patchCall="patchAssignmentCustomGroup"
                  />
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-container>
    <v-container v-else-if="currentView === 'Table'" fluid>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="filteredAssignmentCustomGroups"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.randomAccess`]="{ item }">
              <v-chip class="mr-2">
                <v-icon left>mdi-dice-multiple</v-icon>
                Random access:
                <v-icon v-if="item.randomAccessRequirements.applied">
                  mdi-check
                </v-icon>
                <v-icon v-else>mdi-close</v-icon>
                <v-divider class="mx-2" vertical />
                Whitebox {{ item.randomAccessRequirements.whitebox }}, Blackbox
                {{ item.randomAccessRequirements.blackbox }}
              </v-chip>
            </template>
            <template v-slot:[`item.enabled`]="{ item }">
              <enabled-chip
                :title="`Enabled for ${item.name}`"
                :patchObject="{
                  _id: item._id,
                  enabled: item.enabled
                }"
                :patchCall="patchAssignmentCustomGroup"
              />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                color="primary"
                icon
                @click="openAssignmentCustomGroupDialog(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                color="red"
                icon
                @click="deleteAssignmentCustomGroup(item._id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <assignment-custom-group-dialog
      v-model="dialog"
      :itemIsNew="itemIsNew"
      :assignmentCustomGroup="assignmentCustomGroup"
    />
  </v-container>
</template>

<script>
import AssignmentCustomGroupDialog from "@/components/cms/AssignmentCustomGroupDialog.vue";
import AssignmentCustomGroupService from "@/services/AssignmentCustomGroupService.js";
import EnabledChip from "@/components/cms/EnabledChip.vue";
import datesHelperMixin from "@/components/cms/mixins/datesHelperMixin.js";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [datesHelperMixin],
  components: {
    AssignmentCustomGroupDialog,
    EnabledChip
  },
  props: {
    search: String,
    currentView: String
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Users with access",
          value: "usersIDs.length"
        },
        {
          text: "Assignments pool",
          value: "assignmentsIDs.length"
        },
        {
          text: "Random access",
          value: "randomAccess"
        },
        {
          text: "Enabled",
          value: "enabled"
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false
        }
      ],
      dialog: false,
      enabledDialog: false,
      AssignmentCustomGroupService,
      patchDialogObject: {
        objectId: undefined,
        enabled: {
          val: false,
          from: undefined,
          to: undefined
        }
      },
      itemIsNew: false,
      defaultAssignmentCustomGroup: {
        name: "",
        usersIDs: [],
        assignmentsIDs: [],
        enabled: {
          val: true,
          from: new Date(),
          to: new Date(2100, 0, 2)
        },
        randomAccessRequirements: {
          applied: false,
          whitebox: 1,
          blackbox: 1
        }
      },
      assignmentCustomGroup: {
        name: "",
        usersIDs: [],
        assignmentsIDs: [],
        enabled: {
          val: true,
          from: new Date(),
          to: new Date(2100, 0, 2)
        },
        randomAccessRequirements: {
          applied: false,
          whitebox: 1,
          blackbox: 1
        }
      }
    };
  },
  computed: {
    ...mapGetters({ assignmentCustomGroups: "getAssignmentCustomGroups" }),
    filteredAssignmentCustomGroups() {
      if (!this.search) return this.assignmentCustomGroups;
      return this.assignmentCustomGroups.filter(item => {
        const { name } = item;
        const nrUsersToString = item.usersIDs.length.toString();
        const nrAssignmentsToString = item.assignmentsIDs.length.toString();
        const search = this.search.toLowerCase();
        return (
          name.toLowerCase().includes(search) ||
          nrUsersToString.toLowerCase().includes(search) ||
          nrAssignmentsToString.toLowerCase().includes(search)
        );
      });
    }
  },
  methods: {
    ...mapActions({
      getAssignmentCustomGroups: "getAssignmentCustomGroups",
      patchAssignmentCustomGroup: "patchAssignmentCustomGroup",
      deleteAssignmentCustomGroup: "deleteAssignmentCustomGroup"
    }),
    openAssignmentCustomGroupDialog(item = undefined) {
      if (item) {
        this.itemIsNew = false;
        this.assignmentCustomGroup = item;
      } else {
        this.itemIsNew = true;
        this.assignmentCustomGroup = this.defaultAssignmentCustomGroup;
      }
      this.dialog = true;
    },
    openEnabledDialog(item) {
      this.enabledDialog = true;
      Object.assign(this.patchDialogObject, {
        objectId: item._id,
        name: item.name,
        enabled: item.enabled
      });
    }
  },
  created() {
    this.getAssignmentCustomGroups();
  }
};
</script>
