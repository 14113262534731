var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('pop-up',{attrs:{"icon":"mdi-alert","iconColor":"warning","dividerColor":_vm.$vuetify.theme.dark ? "#e27e36" : "#FFC107","titleText":"Warning","text":"Freeze! You have unsaved changes in your submission! Note that switching to another assignment or folder without submitting first will result in losing your changes.","confirmationText":"switch anyway","type":"changeRoute"},on:{"confirm":function($event){return _vm.pushRouteAndUpdateNav()}},model:{value:(_vm.popup),callback:function ($$v) {_vm.popup=$$v},expression:"popup"}}),_c('v-breadcrumbs',{attrs:{"items":_vm.items,"divider":">"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return _c('v-breadcrumbs-item',{staticClass:"body-1",attrs:{"exact":""},on:{"click":function($event){!item.disabled && _vm.breadcrumbClicked(item)}}},[(item.icon && _vm.$vuetify.breakpoint.mdAndDown)?_c('v-icon',{class:{
          'breadcrumbs-item-disabled': item.disabled,
          'breadcrumbs-item-enabled': !item.disabled
        },attrs:{"size":"24"}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_c('span',{staticClass:"breadcrumbs-item",class:{
          'breadcrumbs-item-disabled': item.disabled,
          'breadcrumbs-item-enabled': !item.disabled
        }},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }