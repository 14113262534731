<template>
  <v-dialog :value="value" @input="$emit('input', false)" width="800px">
    <v-card v-if="role">
      <v-card-title>
        {{ !role._id ? "New" : "Editing" }} role
        <v-spacer />
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="role.can && isSuperAdmin">
        <v-container class="pt-0">
          <v-row>
            <v-col cols="6">
              <v-text-field
                :value="role.name"
                label="Role name"
                disabled
                filled
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>Resources</v-col>
            <v-col>Permissions</v-col>
          </v-row>
          <v-row v-for="resource in Object.keys(role.can)" :key="resource">
            <v-col cols="6" class="my-auto">
              <v-icon small @click="$emit('deleteResource', resource)">
                mdi-close
              </v-icon>
              {{ resource }}
            </v-col>
            <v-divider vertical class="mx-3 my-3"></v-divider>
            <div
              v-for="(permission, i) in ['READ', 'CREATE', 'UPDATE', 'DELETE']"
              :key="i"
            >
              <v-simple-checkbox
                class="mt-2"
                :ripple="false"
                :value="role.can[resource].includes(permission)"
                @input="permissionChange(resource, permission, $event)"
              ></v-simple-checkbox>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="mr-5" v-bind="attrs" v-on="on">
                    {{ permission }}
                  </span>
                </template>
                <span>{{ tooltip(resource, permission) }}</span>
              </v-tooltip>
            </div>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-btn block @click="newResourceDialog = true">
                New resource
                <v-icon right>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-dialog v-model="newResourceDialog" max-width="400px">
              <v-card>
                <v-card-title>New resource</v-card-title>
                <v-card-text>
                  <v-text-field
                    hide-details
                    :value="newResourceText"
                    @input="v => (newResourceText = v.toUpperCase())"
                    label="Type a new resource..."
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="
                      permissionChange(newResourceText, 'READ', true);
                      newResourceDialog = false;
                      newResourceText = '';
                    "
                  >
                    add
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="save">
          <v-icon left color="success">mdi-content-save</v-icon>
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    role: {
      type: Object
    }
  },
  methods: {
    ...mapActions(["patchRole", "deleteRole"]),
    async save() {
      await this.patchRole({ id: this.role._id, data: this.role });
      this.$emit("input", false);
    },
    tooltip(resource, permission) {
      return this.canDescriptions[resource]
        ? this.canDescriptions[resource][permission]
        : "";
    },
    permissionChange(resource, permission, value) {
      this.$emit("permissionChange", resource, permission, value);
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: "getLoggedUser"
    }),
    isSuperAdmin() {
      return this.loggedUser.isSuperAdmin;
    }
  },
  data: () => ({
    newResourceText: "",
    newResourceDialog: false,
    canDescriptions: {
      USER: {
        READ: "Read user",
        CREATE: "Create user",
        UPDATE: "Update user",
        DELETE: "Delete user"
      },
      ASSIGNMENT: {
        READ: "Read assignment",
        CREATE: "Create assignment",
        UPDATE: "Update assignment",
        DELETE: "Delete assignment"
      },
      COMMUNITY: {
        READ: "Read community",
        CREATE: "Create community",
        UPDATE: "Update community",
        DELETE: "Delete community"
      },
      ROLE: {
        READ: "Read role",
        CREATE: "Create role",
        UPDATE: "Update role",
        DELETE: "Delete role"
      },
      SUBMISSION: {
        READ: "Read submission",
        CREATE: "Create submission",
        UPDATE: "Update submission",
        DELETE: "Delete submission"
      },
      RUNLOG: {
        READ: "Read runlog",
        CREATE: "Create runlog",
        UPDATE: "Update runlog",
        DELETE: "Delete runlog"
      },
      DOCKERLOG: {
        READ: "Read dockerlog",
        CREATE: "Create dockerlog",
        UPDATE: "Update dockerlog",
        DELETE: "Delete dockerlog"
      },
      ASSIGNMENTACCESS: {
        READ: "Read assignmentaccess",
        CREATE: "Create assignmentaccess",
        UPDATE: "Update assignmentaccess",
        DELETE: "Delete assignmentaccess"
      },
      ASSIGNMENTOVERRIDE: {
        READ: "Read assignmentoverride",
        CREATE: "Create assignmentoverride",
        UPDATE: "Update assignmentoverride",
        DELETE: "Delete assignmentoverride"
      },
      ASSIGNMENTGROUP: {
        READ: "Read assignmentgroup",
        CREATE: "Create assignmentgroup",
        UPDATE: "Update assignmentgroup",
        DELETE: "Delete assignmentgroup"
      },
      FEEDBACK: {
        READ: "Read feedback",
        CREATE: "Create feedback",
        UPDATE: "Update feedback",
        DELETE: "Delete feedback"
      },
      INVITATIONLINK: {
        READ: "Read invitation links",
        CREATE: "Create invitation links",
        UPDATE: "Update invitation links",
        DELETE: "Delete invitation links"
      }
    }
  })
};
</script>
