<template>
  <v-container id="links" fluid class="pa-0">
    <v-toolbar class="mb-2">
      <v-icon size="30" class="mr-2" color="yellow">mdi-lightbulb</v-icon>
      <v-toolbar-title>
        {{ linksDescription }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="openLinkDialog()" color="primary" large rounded>
        <span v-if="$vuetify.breakpoint.mdAndUp">Create</span>
        <v-icon v-else>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row v-if="currentView === 'Grid'">
      <v-col
        v-for="(link, i) of filteredLinks"
        :key="i"
        :cols="12"
        :lg="6"
        :xl="4"
      >
        <v-card>
          <v-card-title v-if="link.roleID">
            <v-icon class="mr-2">mdi-link</v-icon>
            {{ getCommunityNameById(link.roleID.communityID) }}
            <v-chip class="mx-2">{{ link.roleID.name }}</v-chip>
            <v-spacer></v-spacer>
            <v-btn icon @click="signupCodeToClipboard(link)">
              <v-icon>mdi-clipboard-outline</v-icon>
            </v-btn>
            <v-btn color="primary" icon @click="openLinkDialog(link)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn color="red" icon @click.stop="deleteLink(link._id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle v-if="link.issuerID" class="pb-1">
            Issued by: {{ link.issuerID.firstName }}
            {{ link.issuerID.lastName }}
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row>Sign up code</v-row>
              <v-row>
                <v-chip class="mr-2" @click="signupCodeToClipboard(link)">
                  {{ link.value }}
                </v-chip>
              </v-row>
              <v-row>Custom folders</v-row>
              <v-row>
                <v-chip
                  v-for="(customGroup, i) in link.assignmentCustomGroupIDs"
                  :key="i"
                  class="mr-2"
                  color="primary"
                >
                  {{ customGroup.name }}
                </v-chip>
              </v-row>
              <v-row>
                <enabled-chip
                  :title="`Enabled for signup code ${link.value}`"
                  :patchObject="{
                    _id: link._id,
                    enabled: link.enabled
                  }"
                  :patchCall="patchLink"
                />
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="currentView === 'Table'">
      <v-col cols="12">
        <v-data-table
          :items="filteredLinks"
          fixed-header
          height="calc(100vh - 350px)"
          :headers="headers"
          :items-per-page="25"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100, -1]
          }"
          :loading="loading"
          class="elevation-1 clickable-row"
          @click:row="signupCodeToClipboard"
        >
          <template v-slot:[`item.community`]="{ item }">
            {{ getCommunityNameById(item.roleID.communityID) }}
          </template>
          <template v-slot:[`item.assignmentCustomGroupIDs`]="{ item }">
            <v-chip
              v-for="(customGroup, i) in item.assignmentCustomGroupIDs"
              :key="i"
              class="mr-2"
              color="primary"
            >
              {{ customGroup.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <v-btn icon @click="signupCodeToClipboard(item)">
              <v-icon>mdi-clipboard-outline</v-icon>
            </v-btn>
            {{ item.value }}
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <enabled-chip
              :title="`Enabled for signup code ${item.value}`"
              :patchObject="{
                _id: item._id,
                enabled: item.enabled
              }"
              :patchCall="patchLink"
            />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="primary" icon @click="openLinkDialog(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn color="red" icon @click="deleteLink(item._id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <link-dialog v-model="dialog" :link="link" :itemIsNew="itemIsNew" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import datesHelperMixin from "@/components/cms/mixins/datesHelperMixin.js";
import LinkDialog from "./LinkDialog.vue";
import EnabledChip from "@/components/cms/EnabledChip.vue";

export default {
  mixins: [datesHelperMixin],
  components: {
    LinkDialog,
    EnabledChip
  },
  props: {
    search: String,
    currentView: String
  },
  data: () => ({
    linksDescription: "Invitation links for community roles",
    dialog: false,
    itemIsNew: false,
    defaultLink: {
      roleID: "",
      assignmentCustomGroupIDs: [],
      enabled: {
        val: true,
        from: new Date(),
        to: new Date(2100, 0, 2)
      }
    },
    link: {
      roleID: "",
      assignmentCustomGroupIDs: [],
      enabled: {
        val: true,
        from: new Date(),
        to: new Date(2100, 0, 2)
      }
    }
  }),
  computed: {
    ...mapGetters({
      loading: "getLoading",
      links: "getLinks",
      roles: "getRoles",
      communities: "getCommunities",
      currentRole: "getCurrentRole",
      loggedUser: "getLoggedUser"
    }),
    availableRoles() {
      if (!this.roles) return [];
      if (this.loggedUser.isSuperAdmin) return this.roles;
      return this.roles.slice(
        this.roles.findIndex(i => i.name === this.currentRole) + 1,
        this.roles.length
      );
    },
    filteredLinks() {
      if (!this.search) return this.links;
      return this.links.filter(link => {
        const { roleID, issuerID } = link;
        const { name, communityID } = roleID;
        const search = this.search.toLowerCase();
        return (
          name.toLowerCase().includes(search) ||
          issuerID.username.toLowerCase().includes(search) ||
          this.communities
            .find(community => community._id == communityID)
            .name.toLowerCase()
            .includes(search)
        );
      });
    },
    headers() {
      return [
        {
          text: "Community",
          value: "community",
          width: "250px"
        },
        {
          text: "Role",
          value: "roleID.name"
        },
        {
          text: "Custom folders",
          value: "assignmentCustomGroupIDs"
        },
        {
          text: "Issuer",
          value: "issuerID.username",
          hide: !this.loggedUser.isSuperAdmin
        },
        {
          text: "Sign up code",
          value: "value"
        },
        {
          text: "Enabled",
          value: "enabled"
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false
        }
      ].filter(h => !h.hide);
    }
  },
  methods: {
    ...mapActions({
      addLink: "addLink",
      deleteLink: "deleteLink",
      patchLink: "patchLink"
    }),
    openLinkDialog(item = undefined) {
      if (item) {
        this.itemIsNew = false;
        this.link = item;
      } else {
        this.itemIsNew = true;
        this.link = this.defaultLink;
      }
      this.dialog = true;
    },
    createLink() {
      this.addLink(this.link).then(() => {
        this.link = {};
      });
    },
    getCommunityNameById(id) {
      const community = this.communities.find(c => c._id === id);
      if (!community) return;
      const { name } = community;
      if (!name) return;
      return name;
    },
    signupCodeToClipboard(link) {
      const signupCode = link.value;
      navigator.clipboard.writeText(signupCode);
      this.$store.commit("alert", {
        text: `Signup code ${signupCode} copied to clipboard`,
        color: "success",
        timeout: 7000
      });
    }
  }
};
</script>
<style scoped>
.clickable-row >>> tbody tr :hover {
  cursor: pointer;
}
</style>
