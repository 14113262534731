<template>
  <v-dialog
    :value="value"
    persistent
    @keydown.esc="closeDialog()"
    max-width="1000px"
  >
    <v-card>
      <v-card-title>
        <span class="ml-2">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <enabled-new
          v-if="value"
          v-model="localPatchObject.enabled"
          :switchLabel="switchLabel"
        />
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn @click="saveChanges()">
          <v-icon color="success" left>mdi-content-save</v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import EnabledNew from "./EnabledNew.vue";
export default {
  components: {
    EnabledNew
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    switchLabel: {
      type: String,
      required: false,
      default: "Enabled"
    },
    patchObject: {
      type: Object,
      required: true
    },
    patchCall: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      localPatchObject: undefined
    };
  },
  methods: {
    async saveChanges() {
      try {
        await this.patchCall({
          id: this.localPatchObject._id,
          data: { enabled: this.localPatchObject.enabled }
        });
      } catch (error) {
        this.$store.commit("alert", {
          color: "error",
          text:
            error?.response?.data?.message || "Failed to update enabled values",
          timeout: 5000
        });
      }
      this.$emit("input", false);
    },
    closeDialog() {
      this.$emit("input", false);
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.localPatchObject = cloneDeep(this.patchObject);
      }
    }
  },
  created() {
    this.localPatchObject = cloneDeep(this.patchObject);
  }
};
</script>
