function getNodeById(id, node) {
    const reduce = [].reduce;
    function runner(result, node) {
        if (result || !node) return result;
        if (node.id === id) return node;
        return (
            runner(null, node.children) ||
            reduce.call(Object(node), runner, result)
        );
    }
    return runner(null, node);
}
export default {
    setCommunities(state, payload) {
        state.communities = payload;
    },
    setRoles(state, payload) {
        state.roles = payload;
    },
    setUsers(state, payload) {
        state.users = payload;
    },
    setLinks(state, payload) {
        state.links = payload;
    },
    setAssignments(state, payload) {
        state.assignments = payload;
    },
    setAssignmentCustomGroups(state, payload) {
        state.assignmentCustomGroups = payload;
    },
    setDashboard(state, payload) {
        state.dashboard = payload;
    },
    setStatistics(state, payload) {
        state.statistics = payload;
    },
    setLoading(state, payload) {
        payload ? state.loading++ : state.loading--;
    },
    alert(state, payload) {
        if (payload == null) return;
        state.alert.color = payload.color;
        state.alert.text = payload.text;
        state.alert.button = payload.button;
        state.alert.show = true;
        // Set individual timer
        clearTimeout(state.alert.timeout);
        state.alert.timeout = setTimeout(() => {
            state.alert.show = false;
        }, payload.timeout);
    },
    setIsUserLoggedin(state, payload) {
        state.isUserLoggedin = payload;
    },
    setAssignmentTree(state, payload) {
        state.tree[0].children = payload;
    },
    setUserAssignmentTree(state, payload) {
        const folder = getNodeById(payload.id, state.tree);
        folder.children = payload.children;
    },
    setLoggedUser(state, payload) {
        Object.assign(state.loggedUser, payload);
    },
    setUnsavedChangesToSubmission(state, payload) {
        state.unsavedChangesToSubmission = payload;
    },
    setSubmissionCount(state, payload) {
        const { id, count } = payload;
        const file = getNodeById(id, state.tree);
        file.submissions = count;
    },
};
