import axiosInstance from '@/services/AxiosInstance';

export default {
  getAssignments() {
    return axiosInstance({
      method: 'get',
      url: 'assignment/',
    });
  },
  patchAssignment(id, data) {
    return axiosInstance({
      method: 'patch',
      url: 'assignment/' + id,
      data
    });
  },
  deleteAssignment(id) {
    return axiosInstance({
      method: 'delete',
      url: 'assignment/' + id,
    });
  },
  getUserAssignments(userid) {
    return axiosInstance({
      method: 'get',
      url: 'assignment/user/' + userid,
    });
  },
  getAssignmentInfo(id) {
    return axiosInstance({
      method: 'get',
      url: 'assignment/' + id,
    });
  },
  runAssignment(id, data) {
    return axiosInstance({
      method: 'post',
      url: 'runlog/' + id + '/run',
      data
    });
  },
  getConsole(id, sha) {
    return axiosInstance({
      method: 'get',
      url: 'dockerlog/' + id + '/console/' + sha
    })
  },
  submitAssignment(id, data) {
    return axiosInstance({
      method: 'post',
      url: 'submission/' + id + '/submit',
      data
    })
  },
  patchRunLog(assid, id, data) {
    return axiosInstance({
      method: 'patch',
      url: 'runlog/' + assid + '/' + id,
      data
    })
  },
  getRunLogs(assid) {
    return axiosInstance({
      method: 'get',
      url: 'runlog/' + assid,
    });
  },
  deleteRunLog(assid, id) {
    return axiosInstance({
      method: 'delete',
      url: 'runlog/' + assid + '/' + id,
    });
  }
};
