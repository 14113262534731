<script>
import { Bar, mixins } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
const BarChart = {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ["chartData", "options"],
  mounted() {
    this.addPlugin(ChartDataLabels); // Register the plugin
    this.renderChart(this.chartData, this.options);
  }
};
export default BarChart;
</script>
