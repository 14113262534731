import axiosInstance from '@/services/AxiosInstance';

export default {
    // Users
    getUser() {
        return axiosInstance({
            method: 'get',
            url: 'user/',
        });
    },
    postUser(data) {
        return axiosInstance({
            method: 'post',
            url: 'user/',
            data
        });
    },
    postUserWithLink(data) {
        return axiosInstance({
            method: 'post',
            url: 'user/signup-link',
            data
        });
    },
    getUserProfile() {
        return axiosInstance({
            method: 'get',
            url: '/user/profile/',
        });
    },
    updateUserProfile(data) {
        return axiosInstance({
            method: 'patch',
            url: '/user/profile/',
            data
        });
    },
    updateUserActiveProfile(data) {
        return axiosInstance({
            method: 'patch',
            url: '/user/profile/active/',
            data
        });
    },
    patchUser(id, data) {
        return axiosInstance({
            method: 'patch',
            url: 'user/' + id,
            data
        });
    },
    deleteUser(id) {
        return axiosInstance({
            method: 'delete',
            url: 'user/' + id,
        });
    },
    // Communities
    getCommunity() {
        return axiosInstance({
            method: 'get',
            url: 'community/',
        });
    },
    postCommunity(data) {
        return axiosInstance({
            method: 'post',
            url: 'community/',
            data
        });
    },
    patchCommunity(id, data) {
        return axiosInstance({
            method: 'patch',
            url: 'community/' + id,
            data
        });
    },
    deleteCommunity(id) {
        return axiosInstance({
            method: 'delete',
            url: 'community/' + id,
        });
    },
    // Links
    getLink() {
        return axiosInstance({
            method: 'get',
            url: 'signup-link/',
        });
    },
    postLink(data) {
        return axiosInstance({
            method: 'post',
            url: 'signup-link/',
            data
        });
    },
    patchLink(id, data) {
        return axiosInstance({
            method: 'patch',
            url: 'signup-link/' + id,
            data
        });
    },
    deleteLink(id) {
        return axiosInstance({
            method: 'delete',
            url: 'signup-link/' + id,
        });
    },
    // Roles
    getRole() {
        return axiosInstance({
            method: 'get',
            url: 'role/',
        });
    },
    postRole(data) {
        return axiosInstance({
            method: 'post',
            url: 'role/',
            data
        });
    },
    patchRole(id, data) {
        return axiosInstance({
            method: 'patch',
            url: 'role/' + id,
            data
        });
    },
    deleteRole(id) {
        return axiosInstance({
            method: 'delete',
            url: 'role/' + id,
        });
    },
    // Dashboard
    getDashboard(data) {
        return axiosInstance({
            method: 'post',
            url: 'user/dashboard/',
            data
        });
    },
    // User Statistics
    getStatistics(data) {
        return axiosInstance({
            method: 'post',
            url: 'user/statistics/',
            data
        });
    },
};
