<template>
  <v-container fluid>
    <v-row justify="space-between">
      <v-col cols="12" md="2">
        <v-switch
          v-model="localEnabled.val"
          inset
          :label="switchLabel"
          class="pt-0"
        />
      </v-col>
      <v-col cols="12" md="5">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="700px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="From"
              :hint="stampsHint(localEnabled.from)"
              persistent-hint
              dense
              rounded
              filled
              readonly
              v-bind="attrs"
              v-on="on"
              :value="extractDateTimeWithGmtOffset(localEnabled.from)"
            />
          </template>
          <v-date-picker
            class="elevation-0"
            :max="extractDate(forever)"
            v-model="dateFrom"
          ></v-date-picker>
          <v-time-picker
            class="elevation-0"
            format="24hr"
            v-model="timeFrom"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="5">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="700px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="To"
              :hint="stampsHint(localEnabled.to)"
              persistent-hint
              dense
              rounded
              filled
              readonly
              v-bind="attrs"
              v-on="on"
              :value="extractDateTimeWithGmtOffset(localEnabled.to)"
            ></v-text-field>
          </template>
          <v-date-picker
            class="elevation-0"
            :max="extractDate(forever)"
            :color="clockColor"
            v-model="dateTo"
          ></v-date-picker>
          <v-time-picker
            class="elevation-0"
            format="24hr"
            :color="clockColor"
            v-model="timeTo"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import datesHelperMixin from "@/components/cms/mixins/datesHelperMixin.js";
export default {
  mixins: [datesHelperMixin],
  data() {
    return {
      forever: new Date(2100, 0, 2),
      localEnabled: {
        val: true,
        from: undefined,
        to: undefined
      },
      timeFrom: undefined,
      timeTo: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      menu1: false,
      menu2: false
    };
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    switchLabel: {
      type: String,
      required: false,
      default: "Enabled"
    }
  },
  methods: {
    initData() {
      Object.assign(this.localEnabled, this.value);
      this.localEnabled.from =
        this.localEnabled.from instanceof Date
          ? this.localEnabled.from
          : new Date(this.localEnabled.from);
      this.localEnabled.to =
        this.localEnabled.to instanceof Date
          ? this.localEnabled.to
          : new Date(this.localEnabled.to);
      this.timeFrom = this.extractTime(this.localEnabled.from);
      this.timeTo = this.extractTime(this.localEnabled.to);
      this.dateFrom = this.extractDate(this.localEnabled.from);
      this.dateTo = this.extractDate(this.localEnabled.to);
    },
    stampsHint(timestamp) {
      return `DD-MM-YYYY, HH:MM (24h) ${this.extractTimezone(
        timestamp
      )}, ${this.extractGmtOffset(timestamp)}`;
    },
    closeDialog() {
      this.$emit("input", false);
    }
  },
  computed: {
    isForever() {
      return this.localEnabled.to > this.forever;
    },
    clockColor() {
      return !this.isForever ? "primary" : "secondary";
    }
  },
  created() {
    this.initData();
  },
  watch: {
    localEnabled: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true
    },
    timeFrom(val) {
      this.localEnabled.from = new Date(`${this.dateFrom} ${val}`);
    },
    dateFrom(val) {
      this.localEnabled.from = new Date(`${val} ${this.timeFrom}`);
    },
    timeTo(val) {
      this.localEnabled.to = new Date(`${this.dateTo} ${val}`);
    },
    dateTo(val) {
      this.localEnabled.to = new Date(`${val} ${this.timeTo}`);
    }
  }
};
</script>
<style>
.v-picker {
  border-radius: 0% !important;
}
.v-picker__title {
  max-height: 88px;
}
</style>
