<template>
  <v-card id="preferences" height="90vh" scrollable :loading="loading">
    <v-card-title class="headline">
      <v-btn
        v-if="isSuperAdmin || currentRole !== 'USER'"
        @click="showTabs = !showTabs"
        :color="showTabs ? 'primary' : ''"
        class="mr-2"
        x-large
        icon
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <div v-if="$vuetify.breakpoint.mdAndUp">Preferences</div>
      <div
        v-if="
          (isSuperAdmin || currentRole !== 'USER') &&
          $vuetify.breakpoint.mdAndUp
        "
        class="grey--text mx-3"
      >
        {{ ">" }}
      </div>
      <div
        v-if="
          (isSuperAdmin || currentRole !== 'USER') &&
          ($vuetify.breakpoint.mdAndUp || currentTab === 'Dashboard')
        "
        class="primary--text"
      >
        {{ currentTab }}
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        v-if="isSuperAdmin || currentRole !== 'USER'"
        v-show="showOptions"
        v-model="search[currentTab]"
        clearable
        label="Search"
        :prepend-inner-icon="$vuetify.breakpoint.mdAndUp ? 'search' : ''"
        style="max-width: 30%"
        hide-details
        filled
        rounded
        dense
      ></v-text-field>
      <v-btn-toggle
        v-if="isSuperAdmin || currentRole !== 'USER'"
        v-show="showOptions"
        v-model="currentView"
        class="mx-3"
        mandatory
        rounded
      >
        <v-btn v-for="view in views" :key="view.name" :value="view.name">
          <v-icon>{{ view.icon }}</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">
            {{ view.name }}
          </span>
        </v-btn>
      </v-btn-toggle>
      <v-btn @click="$emit('close')" icon large class="mx-1">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-3">
      <preferences-navigation
        v-model="showTabs"
        :search="search"
        :currentView="currentView"
        @tabChange="v => (currentTab = v)"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import PreferencesNavigation from "@/components/cms/PreferencesNavigation.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PreferencesNavigation
  },
  data: () => ({
    showTabs: false,
    search: {},
    currentTab: "Dashboard",
    views: [
      {
        name: "Table",
        icon: "mdi-table"
      },
      {
        name: "Grid",
        icon: "mdi-view-grid-outline"
      }
    ],
    currentView: "Table"
  }),
  computed: {
    ...mapGetters({
      loading: "getLoading",
      loggedUser: "getLoggedUser",
      currentRole: "getCurrentRole"
    }),
    isSuperAdmin() {
      return this.loggedUser.isSuperAdmin;
    },
    showOptions() {
      return [
        "Users",
        "Communities",
        "Invitation Links",
        "Assignments",
        "Custom Folders"
      ].includes(this.currentTab);
    }
  }
};
</script>
<style >
#preferences {
  padding: 0;
}
</style>