<template>
  <v-dialog
    :value="value"
    @keydown.esc="cancel()"
    persistent
    max-width="700"
    content-class="popup"
  >
    <v-card :color="color">
      <v-card-title
        class="headline"
        :class="{
          'text-black': color === 'white'
        }"
      >
        <v-container class="py-0">
          <v-row>
            <v-col cols="1">
              <v-icon
                v-if="icon"
                :color="iconColor"
                :size="iconSize"
                class="mr-2"
              >
                {{ icon }}
              </v-icon>
            </v-col>
            <v-col cols="3" class="pl-0" style="margin-top: 3px">
              <span class="mt-1">
                {{ titleText }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-divider :style="`background-color: ${dividerColor};`"></v-divider>
      <v-card-text
        class="mt-2"
        :class="{
          'text-black': color === 'white'
        }"
      >
        <div style="word-break: break-word">
          {{ text }}
        </div>
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.stop="cancel" text color="error">Cancel</v-btn>
        <v-btn @click="confirm" text color="primary">
          {{ confirmationText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    color: String,
    titleText: String,
    text: String,
    confirmationText: String,
    icon: String,
    iconColor: String,
    dividerColor: String,
    iconSize: { type: [String, Number], default: 34 },
    type: String
  },
  methods: {
    confirm() {
      if (this.type == "changeRoute") {
        this.$store.commit("setUnsavedChangesToSubmission", false);
      }
      this.$emit("input", false);
      this.$emit("confirm", this.type);
    },
    cancel() {
      this.$emit("input", false);
    }
  }
};
</script>

<style scoped>
.v-dialog.popup {
  border-radius: 14px;
}
</style>
