<template>
    <v-row>
        <v-col :cols="12" :md="6">
            <v-toolbar dense>
                <v-toolbar-title>
                    Users with access
                    <v-chip color="primary">
                        {{ localSelectedUsers.length }} selected
                    </v-chip>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="searchUsers"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-toolbar>
            <v-data-table
                v-model="localSelectedUsers"
                :headers="headersUsers"
                :search="searchUsers"
                :loading="loading"
                :items="users"
                item-key="_id"
                show-select
                class="mt-1 elevation-3"
                :height="tablesHeight"
                :items-per-page="-1"
                fixed-header
                hide-default-footer
            ></v-data-table>
        </v-col>
        <v-col :cols="12" :md="6">
            <v-toolbar dense>
                <v-toolbar-title>
                    Assignments pool
                    <v-chip color="primary">
                        {{ localSelectedAssignments.length }} selected ({{
                            localSelectedAssignments.filter(
                                (assignment) => !assignment.blackbox,
                            ).length
                        }}
                        whitebox,
                        {{
                            localSelectedAssignments.filter(
                                (assignment) => assignment.blackbox,
                            ).length
                        }}
                        blackbox)
                    </v-chip>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="searchAssignments"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-toolbar>
            <v-data-table
                v-model="localSelectedAssignments"
                group-by="name"
                :headers="headersAssignments"
                :search="searchAssignments"
                :loading="loading"
                :items="assignments"
                item-key="_id"
                show-select
                class="mt-1 elevation-3"
                :height="tablesHeight"
                :items-per-page="-1"
                fixed-header
                hide-default-footer
            >
                <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                    <th colspan="2">
                        <v-container>
                            <v-row>
                                <v-checkbox
                                    :value="
                                        getAssignmentsFolderSelected(
                                            items[0].name,
                                        )
                                    "
                                    @click="userUpdatedFolderSelection = true"
                                    @change="
                                        setAssignmentsFolderSelected(
                                            $event,
                                            items[0].name,
                                        )
                                    "
                                />
                                <v-chip class="mt-4" @click="toggle">
                                    {{
                                        items[0].name
                                            .replace(/([A-Z0-9])/g, ' $1')
                                            .trim()
                                            .replace(/^./, (str) =>
                                                str.toUpperCase(),
                                            )
                                    }}
                                    <v-divider class="mx-2" vertical />
                                    <v-icon>
                                        {{
                                            isOpen
                                                ? 'mdi-menu-up'
                                                : 'mdi-menu-down'
                                        }}
                                    </v-icon>
                                </v-chip>
                            </v-row>
                        </v-container>
                    </th>
                    <th colspan="1">
                        <v-icon v-if="items[0].blackbox">mdi-check</v-icon>
                    </th>
                </template>
                <template v-slot:[`item.blackbox`]="{ item }">
                    <v-icon v-if="item.blackbox">mdi-check</v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    props: {
        tablesHeight: {
            type: String,
            required: false,
            default: '40vh',
        },
        selectedUsers: {
            type: Array,
            required: true,
        },
        selectedAssignments: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        localSelectedUsers: [],
        localSelectedAssignments: [],
        headersUsers: [
            { text: 'Username', value: 'username' },
            { text: 'Full name', value: 'fullName' },
            { text: 'Email', value: 'email' },
            { text: 'Role', value: 'activeRoleName' },
        ],
        headersAssignments: [
            { text: 'Name', value: 'prettyName', sortable: true },
            { text: 'Blackbox', value: 'blackbox', sortable: true },
        ],
        searchUsers: '',
        searchAssignments: '',
        userUpdatedFolderSelection: false,
    }),
    computed: {
        ...mapGetters({
            loading: 'getLoading',
            usersFromStore: 'getUsers',
            assignments: 'getAssignments',
        }),
        users() {
            return this.usersFromStore.map((user) => ({
                ...user,
                activeRoleName: user.roleID.find(
                    (role) => user.roleIDActive === role._id,
                ).name,
            }));
        },
    },
    methods: {
        ...mapActions({
            getUsers: 'getUsers',
            getAssignments: 'getAssignments',
        }),
        getAssignmentsFolderSelected(folderName) {
            const assignmentsFromFolder = this.assignments.filter(
                (assignment) => assignment.name === folderName,
            );
            return assignmentsFromFolder.every((assignment) =>
                this.localSelectedAssignments.includes(assignment),
            );
        },
        setAssignmentsFolderSelected(val, folderName) {
            this.$nextTick(() => {
                if (this.userUpdatedFolderSelection) {
                    this.userUpdatedFolderSelection = false;
                    if (val) {
                        this.localSelectedAssignments =
                            this.localSelectedAssignments.concat(
                                this.assignments.filter(
                                    (assignment) =>
                                        assignment.name === folderName,
                                ),
                            );
                    } else {
                        this.localSelectedAssignments =
                            this.localSelectedAssignments.filter(
                                (assignment) => assignment.name !== folderName,
                            );
                    }
                }
            });
        },
    },
    watch: {
        localSelectedUsers: function (val) {
            this.$emit(
                'updateSelectedUsers',
                val.map((user) => user._id),
            );
        },
        localSelectedAssignments: function (val) {
            this.$emit(
                'updateSelectedAssignments',
                val.map((assignment) => assignment._id),
            );
        },
    },
    created() {
        Promise.all([this.getUsers(), this.getAssignments()]).then(() => {
            this.localSelectedUsers = this.users.filter((user) =>
                this.selectedUsers.includes(user._id),
            );
            this.localSelectedAssignments = this.assignments.filter(
                (assignment) =>
                    this.selectedAssignments.includes(assignment._id),
            );
        });
    },
};
</script>
