import PreferencesService from '../services/PreferencesService';
import AssignmentService from '../services/AssignmentService';
import AssignmentCustomGroupService from '../services/AssignmentCustomGroupService';

export default {
    // COMMUNITIES
    async getCommunities({ commit }) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.getCommunity();
            commit('setCommunities', res.data.data);
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message || 'Failed to get communities',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async addCommunity({ dispatch, commit }, data) {
        commit('setLoading', true);
        try {
            await PreferencesService.postCommunity(data);
            await dispatch('getCommunities');
            await dispatch('getRoles');
            await dispatch('getUsers');
            await dispatch('getUserProfile');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message || 'Failed to create community',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async patchCommunity({ dispatch, commit }, { id, data }) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.patchCommunity(id, data);
            await dispatch('getCommunities');
            commit('alert', {
                color: 'success',
                text: res.data.message || 'Community updated successfully',
                timeout: 5000,
            });
            return res;
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message || 'Failed to update community',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async deleteCommunity({ dispatch, commit }, id) {
        if (
            !confirm(
                'Are you sure you want to delete this community? Please note that this action will revoke all user access to the community!',
            )
        )
            return;
        commit('setLoading', true);
        try {
            await PreferencesService.deleteCommunity(id);
            await dispatch('getCommunities');
            await dispatch('getRoles');
            await dispatch('getUsers');
            await dispatch('getLinks');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message || 'Failed to delete community',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    // ROLES
    async getRoles({ commit }) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.getRole();
            commit('setRoles', res.data.data);
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to load roles',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async addRole({ dispatch, commit }, data) {
        commit('setLoading', true);
        try {
            await PreferencesService.postRole(data);
            await dispatch('getRoles');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to create role',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async patchRole({ dispatch, commit }, { id, data }) {
        commit('setLoading', true);
        try {
            await PreferencesService.patchRole(id, data);
            await dispatch('getRoles');
            await dispatch('getCommunities');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to update role',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async deleteRole({ dispatch, commit }, id) {
        if (!confirm('Are you sure you want to delete this role?')) return;
        commit('setLoading', true);
        try {
            await PreferencesService.deleteRole(id);
            await dispatch('getRoles');
            await dispatch('getCommunities');
            await dispatch('getUsers');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to delete role',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    // USERS
    async getUsers({ commit }) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.getUser();
            commit('setUsers', res.data.data);
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to get users',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async addUser({ dispatch, commit }, user) {
        commit('setLoading', true);
        try {
            await PreferencesService.postUser(user);
            await dispatch('getUsers');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to create user',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async patchUser({ dispatch, commit }, { id, data }) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.patchUser(id, data);
            await dispatch('getCommunities');
            await dispatch('getUsers');
            await dispatch('getUserProfile');
            commit('alert', {
                color: 'success',
                text: res.data.message || 'User updated successfully',
                timeout: 5000,
            });
            return res;
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to update user',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async deleteUser({ dispatch, commit }, id) {
        if (!confirm('Are you sure you want to delete this user?')) return;
        commit('setLoading', true);
        try {
            await PreferencesService.deleteUser(id);
            await dispatch('getUsers');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to delete user',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    // PROFILE
    async getUserProfile({ commit }) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.getUserProfile();
            commit('setLoggedUser', res.data.user);
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message || 'Failed to get user profile',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    // LINKS
    async getLinks({ commit }) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.getLink();
            commit('setLinks', res.data.data);
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to get links',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async addLink({ dispatch, commit }, link) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.postLink(link);
            commit('alert', {
                color: 'success',
                text: res.data.message || 'Link created successfully',
                timeout: 5000,
            });
            await dispatch('getLinks');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to create link',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async patchLink({ dispatch, commit }, { id, data }) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.patchLink(id, data);
            await dispatch('getLinks');
            commit('alert', {
                color: 'success',
                text: res.data.message || 'Link updated successfully',
                timeout: 5000,
            });
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to update link',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async deleteLink({ dispatch, commit }, id) {
        if (!confirm('Are you sure you want to delete this link?')) return;
        commit('setLoading', true);
        try {
            const res = await PreferencesService.deleteLink(id);
            commit('alert', {
                color: 'success',
                text: res.data.message || 'Link deleted successfully',
                timeout: 5000,
            });
            await dispatch('getLinks');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to delete link',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    // ASSIGNMENTS
    async getAssignments({ commit }) {
        commit('setLoading', true);
        try {
            const res = await AssignmentService.getAssignments();
            commit('setAssignments', res.data.data);
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message || 'Failed to get assignments',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async patchAssignment({ dispatch, commit }, data) {
        commit('setLoading', true);
        try {
            const { _id, prettyName, bugDescription, bugType, blackbox } = data;
            await AssignmentService.patchAssignment(_id, {
                prettyName,
                bugDescription,
                bugType,
                blackbox,
            });
            await dispatch('getAssignments');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message ||
                    'Failed to update assignment',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async deleteAssignment({ dispatch, commit }, id) {
        if (!confirm('Are you sure you want to delete this assignment?'))
            return;
        commit('setLoading', true);
        try {
            await AssignmentService.deleteAssignment(id);
            await dispatch('getAssignments');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message ||
                    'Failed to delete assignment',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    // ASSIGNMENT CUSTOM GROUPS
    async getAssignmentCustomGroups({ commit }) {
        commit('setLoading', true);
        try {
            const res =
                await AssignmentCustomGroupService.getAssignmentCustomGroups();
            commit('setAssignmentCustomGroups', res.data);
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message ||
                    'Failed to get assignment custom groups',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async addAssignmentCustomGroup({ commit, dispatch }, data) {
        commit('setLoading', true);
        try {
            const res =
                await AssignmentCustomGroupService.postAssignmentCustomGroup(
                    data,
                );
            commit('alert', {
                text:
                    res.data.message ||
                    'Assignment custom group created successfully',
                color: 'success',
                timeout: 5000,
            });
            await dispatch('getAssignmentCustomGroups');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message ||
                    'Failed to create assignment custom group',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    async patchAssignmentCustomGroup({ commit, dispatch }, { id, data }) {
        commit('setLoading', true);
        try {
            const res =
                await AssignmentCustomGroupService.patchAssignmentCustomGroup(
                    id,
                    data,
                );
            commit('alert', {
                text:
                    res.data.message ||
                    'Assignment custom group updated successfully',
                color: 'success',
                timeout: 5000,
            });
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message ||
                    'Failed to update assignment custom group',
                timeout: 5000,
            });
        } finally {
            await dispatch('getAssignmentCustomGroups');
            commit('setLoading', false);
        }
    },
    async deleteAssignmentCustomGroup({ commit, dispatch }, id) {
        if (
            !confirm(
                'Are you sure you want to delete this folder? Please note that this action will revoke all user access to the folder!',
            )
        )
            return;
        commit('setLoading', true);
        try {
            const res =
                await AssignmentCustomGroupService.deleteAssignmentCustomGroup(
                    id,
                );
            commit('alert', {
                text:
                    res.data.message ||
                    'Assignment custom group deleted successfully',
                color: 'success',
                timeout: 5000,
            });
            await dispatch('getAssignmentCustomGroups');
        } catch (error) {
            commit('alert', {
                color: 'error',
                text:
                    error.response.data.message ||
                    'Failed to delete assignment custom group',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    // DASHBOARD
    async getDashboard({ commit }, data) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.getDashboard(data);
            commit('setDashboard', res.data.data);
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to get dashboard',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
    // STATISTICS
    async getStatistics({ commit }, data) {
        commit('setLoading', true);
        try {
            const res = await PreferencesService.getStatistics(data);
            commit('setStatistics', res.data.data);
        } catch (error) {
            commit('alert', {
                color: 'error',
                text: error.response.data.message || 'Failed to get statistics',
                timeout: 5000,
            });
        } finally {
            commit('setLoading', false);
        }
    },
};
