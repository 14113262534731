<template>
  <v-container id="assignments" fluid class="pa-0">
    <v-toolbar class="mb-2" flat>
      <v-icon size="30" class="mr-2" color="yellow">mdi-lightbulb</v-icon>
      <v-toolbar-title>
        {{ assignmentsDescription }} in
        <strong>
          {{ currentCommunity }}
        </strong>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row
      v-if="currentView === 'Grid'"
      style="max-height: calc(90vh - 150px); overflow-y: auto"
    >
      <v-col
        v-for="(assignment, i) of filteredAssignments"
        :key="i"
        :cols="12"
        :lg="6"
        :xl="4"
      >
        <v-card height="100%" @click="openUpdateAssignmentMenu(assignment)">
          <v-card-title>
            {{ assignment.prettyName }}
            <v-chip v-if="assignment.bugType" class="mx-2">
              {{ assignment.bugType }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              icon
              @click.stop="deleteAssignment(assignment._id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            Description: {{ assignment.bugDescription }}
          </v-card-text>
          <v-card-text v-if="assignment.bugLines.length">
            Lines: {{ assignment.bugLines.join(", ") }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="currentView === 'Table'">
      <v-col cols="12">
        <v-data-table
          :items="filteredAssignments"
          fixed-header
          height="calc(90vh - 230px)"
          :headers="headers"
          :items-per-page="25"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100, -1]
          }"
          :loading="loading"
          class="elevation-0 clickable-row"
          @click:row="openUpdateAssignmentMenu"
        >
          <template v-slot:[`item.blackbox`]="{ item }">
            <v-icon v-if="item.blackbox">mdi-check</v-icon>
          </template>
          <template v-slot:[`item.extension`]="{ item }">
            <v-icon v-if="item.extension === '.py'">mdi-language-python</v-icon>
            <v-icon v-else-if="item.extension === '.c'">mdi-language-c</v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="red" icon @click.stop="deleteAssignment(item._id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="assignmentMenu" max-width="800px">
      <v-card v-if="assignment">
        <v-card-title class="mb-4">
          Assignment Configuration
          <v-spacer></v-spacer>
          <v-btn icon @click="assignmentMenu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="assignment.prettyName"
                  label="Pretty Name"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="assignment.bugDescription"
                  label="Description"
                  :rows="1"
                  auto-grow
                  filled
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="assignment.bugType"
                  label="Bug Type"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="assignment.inputTypes"
                  label="Input Types"
                  filled
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :value="assignment.bugLines"
                  label="Bug Lines"
                  filled
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :value="assignment.extension"
                  label="Extension"
                  filled
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="assignment.blackbox">
                  <template v-slot:label>
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">Blackbox</span>
                      </template>
                      <span>
                        Hides the source code from the user. The user will only
                        see the requirements.
                      </span>
                    </v-tooltip>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="save" :loading="loading">
            <v-icon left color="success">mdi-content-save</v-icon>
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    search: String,
    currentView: String
  },
  data: () => ({
    assignment: {},
    assignmentMenu: false,
    assignmentsDescription: "Assignment Bugs"
  }),
  created() {
    this.getAssignments();
  },
  methods: {
    ...mapActions({
      getAssignments: "getAssignments",
      patchAssignment: "patchAssignment",
      deleteAssignment: "deleteAssignment"
    }),
    openUpdateAssignmentMenu(assignment) {
      this.assignment = Object.assign({}, assignment);
      this.assignmentMenu = true;
    },
    async save() {
      await this.patchAssignment(this.assignment);
      this.assignmentMenu = false;
    }
  },
  computed: {
    ...mapGetters({
      assignments: "getAssignments",
      loading: "getLoading",
      currentCommunity: "getCurrentCommunity"
    }),
    filteredAssignments() {
      if (!this.search) return this.assignments;
      return this.assignments.filter(assignment => {
        const { prettyName, bugDescription, bugType } = assignment;
        const search = this.search.toLowerCase();
        return (
          prettyName.toLowerCase().includes(search) ||
          bugDescription.toLowerCase().includes(search) ||
          bugType.toLowerCase().includes(search)
        );
      });
    },
    headers() {
      return [
        {
          text: "Name",
          value: "prettyName",
          width: "200px"
        },
        {
          text: "Description",
          value: "bugDescription",
          width: "350px"
        },
        {
          text: "Bug Type",
          value: "bugType",
          width: "150px"
        },
        {
          text: "Blackbox",
          value: "blackbox",
          width: "110px"
        },
        {
          text: "Extension",
          value: "extension",
          width: "110px"
        },
        {
          text: "Input Types",
          value: "inputTypes",
          width: "120px"
        },
        {
          text: "Bug Lines",
          value: "bugLines",
          width: "110px"
        },
        {
          text: "Actions",
          value: "actions",
          width: "110px",
          sortable: false
        }
      ];
    }
  }
};
</script>
<style scoped>
.clickable-row >>> tbody tr :hover {
  cursor: pointer;
}
</style>
