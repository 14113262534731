// dateMixin.js
export default {
    methods: {
      extractTime(timestamp) {
        if(!(timestamp instanceof Date)) {
          timestamp = new Date(timestamp);
        }
        const time = timestamp
          .toLocaleTimeString([], { hour12: false })
          .split(" ")[0];
        const timeParts = time.split(":");
        return `${timeParts[0]}:${timeParts[1]}`;
      },
      extractTimezone(timestamp) {
        if (!(timestamp instanceof Date)) {
          timestamp = new Date(timestamp);
        }
        // Get the timezone name
        const timeZoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' })
        .formatToParts(timestamp)
        .find(part => part.type === 'timeZoneName')
        .value;
        return timeZoneName;
      },
      extractGmtOffset(timestamp) {
        if (!(timestamp instanceof Date)) {
          timestamp = new Date(timestamp);
        }
        const offset = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
        .formatToParts(timestamp)
        .find(part => part.type === 'timeZoneName')
        .value;
        return offset;
      },
      extractDate(timestamp) {
        if (!(timestamp instanceof Date)) {
          timestamp = new Date(timestamp);
        }
        const day = timestamp.getDate();
        const month = timestamp.getMonth() + 1; // getMonth() returns 0-11
        const year = timestamp.getFullYear();

        // Pad the day and month with leading zeros if necessary
        const paddedDay = day < 10 ? '0' + day : day;
        const paddedMonth = month < 10 ? '0' + month : month;

        return `${year}-${paddedMonth}-${paddedDay}`;
          },
      extractDateTimeWithGmtOffset(timestamp) {
        if (!(timestamp instanceof Date)) {
          timestamp = new Date(timestamp);
        }
        return `${this.reverseDate(this.extractDate(timestamp))}, ${this.extractTime(timestamp)}, ${this.extractGmtOffset(timestamp)}`;
      },
      reverseDate(dateString) {
        return dateString.split("-").reverse().join("-");
      }
    }
  };
  