<template>
  <div id="submit-btn">
    <v-tooltip color="transparent" left>
      <v-icon v-if="!error" color="success">mdi-checkbox-marked-circle</v-icon>
      <template v-slot:activator="{ on }">
        <v-btn
          x-large
          color="success"
          rounded
          v-on="on"
          @click="askForSubmit"
          class="pr-0"
        >
          Submit
          <v-btn
            :loading="loading"
            dark
            icon
            x-large
            class="ml-2"
            :ripple="false"
          >
            <v-icon color="white">mdi-send</v-icon>
          </v-btn>
        </v-btn>
      </template>
    </v-tooltip>
    <!-- Pop Up -->
    <pop-up
      v-model="popup"
      icon="mdi-alert"
      titleText="Warning"
      iconColor="warning"
      :dividerColor="$vuetify.theme.dark ? `#e27e36` : `#FFC107`"
      text="This action will overwrite your last known submission. Please confirm."
      type="submit"
      confirmationText="submit"
      @confirm="submit"
    />
  </div>
</template>

<script>
import PopUp from "../core/PopUp";

export default {
  props: {
    error: String
  },
  components: {
    PopUp
  },
  data: () => ({
    popup: false,
    loading: false
  }),
  methods: {
    askForSubmit() {
      let e = this.error;
      if (e) {
        this.$store.commit("alert", {
          text: e,
          color: "error",
          timeout: 8000
        });
      } else this.popup = true;
    },
    submit() {
      if (this.loading) return;
      this.loading = true;
      this.$emit("submit");
      setTimeout(
        () =>
          // Enable run
          (this.loading = false),
        1000
      );
    }
  }
};
</script>
