<template>
    <v-menu
        transition="slide-y-transition"
        bottom
        offset-y
        ref="menuRef"
        :close-on-content-click="true"
    >
        <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        v-if="isLogged"
                        v-on="{ ...tooltip, ...menu }"
                        class="px-0"
                        x-large
                        text
                    >
                        <div>
                            <v-avatar class="primary darken-2" size="42">
                                <span
                                    v-if="loggedUser.username"
                                    class="white--text"
                                >
                                    {{ loggedUser.username.slice(0, 2) }}
                                </span>
                            </v-avatar>
                            <v-icon color="primary" size="21"
                                >mdi-chevron-down</v-icon
                            >
                        </div>
                    </v-btn>
                    <v-btn v-else icon v-on="{ ...tooltip, ...menu }">
                        <v-icon>settings</v-icon>
                    </v-btn>
                </template>
                <span>Settings</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-container pa-0>
                <v-list shaped dense>
                    <v-list-item
                        v-if="isLogged"
                        class="px-2 py-2"
                        @click="perform('profile')"
                    >
                        <v-row align="center" no-gutters>
                            <v-col cols="auto">
                                <v-avatar class="primary darken-2" size="55">
                                    <v-icon color="white" size="24">
                                        mdi-account
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="text-center">
                                <div class="header">
                                    {{ loggedUser.firstName }}
                                    {{ loggedUser.lastName }}
                                </div>
                                <div class="subheader">
                                    {{ loggedUser.username }}
                                </div>
                                <div class="subheader">
                                    {{ loggedUser.email }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item>
                    <v-list-item class="mb-5">
                        <v-list-item-content>
                            <v-list-item-title>Roles</v-list-item-title>
                            <profile-switcher />
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-1" v-if="isLogged" />
                    <!-- Preferences -->
                    <v-list-item
                        v-if="
                            (isLogged && loggedUser.isSuperAdmin) ||
                            currentRole !== 'USER'
                        "
                        @click="perform('preferences')"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Preferences</v-list-item-title>
                    </v-list-item>
                    <!-- Dark mode -->
                    <v-list-item @click="dark = !dark" link>
                        <v-list-item-icon>
                            <v-icon :color="dark ? 'white' : 'black'">
                                mdi-invert-colors
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Turn {{ dark ? 'on' : 'off' }} the lights
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- Logout -->
                    <v-list-item
                        v-if="isLogged"
                        @click="perform('logout')"
                        link
                    >
                        <v-list-item-icon>
                            <v-icon color="red">mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Log Out</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-container>
        </v-card>
    </v-menu>
</template>
<script>
import ProfileSwitcher from './ProfileSwitcher';
import { mapGetters } from 'vuex';
export default {
    components: {
        ProfileSwitcher,
    },
    computed: {
        ...mapGetters({
            loggedUser: 'getLoggedUser',
            isLogged: 'getIsUserLoggedin',
            currentRole: 'getCurrentRole',
        }),
        roles() {
            return this.loggedUser.roleID;
        },
        dark: {
            get() {
                return this.$vuetify.theme.dark;
            },
            set(val) {
                this.$vuetify.theme.dark = val;
            },
        },
    },
    methods: {
        perform(action) {
            // Deactivate menu
            this.$refs.menuRef.isActive = false;
            this.$emit(action);
        },
    },
};
</script>
<style scoped>
.subheader {
    font-size: 12px;
    /* font-weight: 400; */
}
.header {
    font-size: 20px;
    font-weight: 600;
}
</style>